
import { getBaseWareLis, getWareCooperateList, exportData } from './api.js'
import Util from "@/utils/utils";
export default {
  data() {
    return {
      arg: {},
      warehouseData: [], // 仓库列表数据
      partnershipDada: [], // 合作关系列表数据
      warehousColumns: [
        {
          label: '仓库',
          prop: 'fullName',
          minWidth: 200,
          ellipsis: true,
        },
        {
          label: '仓库简称',
          prop: 'searchTerm',
          minWidth: 150,
        },
        {
          label: '合作的物料组',
          prop: 'cooperationMaterialNameList',
          minWidth: 200,
          ellipsis: true,
        },
        {
          label: 'CIS编码',
          prop: 'cisCode',
          minWidth: 150,
        },
        {
          label: 'MDM编码',
          prop: 'mdmCode',
          minWidth: 150,
        },
        {
          label: '省',
          prop: 'provinceName',
          minWidth: 150,
        },
        {
          label: '市',
          prop: 'cityName',
          minWidth: 150,
        },
        {
          label: '区',
          prop: 'countyName',
          minWidth: 150,
        },
        {
          label: '乡镇',
          prop: 'townName',
          minWidth: 150,
        },

        {
          label: '门店地址',
          prop: 'shAddress',
          minWidth: 200,
          ellipsis: true,
        },
      ],
      partnerCloumns: [
        {
          label: '分公司',
          prop: 'fgsName',
          minWidth: 150,
        },
        {
          label: '办事处',
          prop: 'orgName',
          minWidth: 150,
        },
        {
          label: '物料组',
          prop: 'baseMatklName',
          minWidth: 120,
        },
        {
          label: '联系人',
          prop: 'managerName',
          minWidth: 120,
        },
        {
          label: '联系电话',
          prop: 'managerTel',
          minWidth: 120,
        },
        {
          label: '合作状态',
          prop: 'djFlagText',
          minWidth: 120,
        },
      ],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      tableLoading: false,
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      pagertwo: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      radio: 'radio',
      partnerName: '',


      materialGroupGroup: [
        {
          id: 1,
          code: 2,
          name: '201138302-莱西粮贸仓库2'
        }
        , {
          id: 2,
          code: 3,
          name: '201138302-莱西粮贸仓库22'
        },
        {
          id: 3,
          code: 4,
          name: '201138302-莱西粮贸仓库24'
        }
      ],// 物料组
      qustionsTypeArr: [
        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },

        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        }, {
          id: "123",
          name: "123",
        }, {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },


      ],
      ButtonGroup1: [
        {
          id: 0,
          name: '咨询'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        }, {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
        {
          id: 1,
          name: '投诉'
        },
      ],
      isActive: 0,
      isActiveLevel: 0,
      organizationValue: undefined,
      materialGroupValue: undefined,
      isView: false,
      orderLoading: false,
      options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                },
              ],
            },
          ],
        }
      ]
    };
  },
  props: {
    shopId: {
      type: Number,

    }
  },
  watch: {
    shopId: function () {
      this.getCKData()
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.getCKData()
    // this.gethzList()
  },
  methods: {
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        "custId": this.shopId,
        ...this.arg
      }
      exportData(data).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.code == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '收货仓库列表.xls')
        }).finally(() => {
          this.orderLoading = false;
        })
      }).catch(err => {
        this.orderLoading = false;
      });
    },
    shaiClick() {
      this.isView = !this.isView;
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getCKData();
    },
    // 查询条件
    searchFun(arg) {

      this.pager.pageNo = 1

      this.arg = arg[0]
      this.getCKData();
    },
    // 仓库列表数据
    async getCKData() {
      this.tableLoading = true
      let data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        "custId": this.shopId,
        ...this.arg
      }
      const res = await getBaseWareLis(data)
      this.warehouseData = res.data.list
      if(this.warehouseData.length > 0) {
        this.warehouseData.forEach(item=>{
          item.cooperationMaterialNameList = item.cooperationMaterialNameList.join(',')
        })
      }
      // this.pager.pageNo = res.data.page
      // this.pager.pageSize = res.data.pageSize
      this.pager.count = res.data.totalRows
      this.total = res.data.totalRows
      this.tableLoading = false
    },

    // 合作关系列表数据
    async gethzList() {
      this.tableLoading = true
      const data = {
        queryPage: {
          "page": this.pagertwo.pageNo,
          "pageSize": this.pagertwo.pageSize,
          "custId": this.shopId,
          "queryParamList": []
        },
        custId: this.shopId,
        wareId: this.warehouseId
      }
      const res = await getWareCooperateList(data)
      this.partnershipDada = res.data.content
      this.pagertwo.pageNo = res.data.page
      this.pagertwo.pageSize = res.data.pageSize
      this.pagertwo.count = res.data.totalRows
      this.total = res.data.totalRows
      this.tableLoading = false
    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record])
          }
        }
      }
    },
    // 选择项更改
    onChange(selRows) {
      this.selectedRowKeys = selRows[0].id;
      this.warehouseId = this.selectedRowKeys;
      if (selRows[0].mdmCode) {
        this.partnerName = selRows[0].mdmCode + '-' + selRows[0].searchTerm
      } else {
        this.partnerName = selRows[0].searchTerm
      }
      this.gethzList()
      setTimeout(() => {
        // document.querySelector('#'+'a01').scrollIntoView(true)
        if(this.partnershipDada.length > 0 ){
          $("body,html").animate(
            {
              scrollTop: $("#a01").offset().top ,
            },
            500
          );
        }
      }, 1000)
    },
  },
};