<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-select
        style="width: 120px!important"
        :value="value"
        @change="handleChange"
        @pressEnter="check"
      >
        <a-select-option value="T">
          分销商
        </a-select-option>
        <a-select-option value="F">
          代理商
        </a-select-option>
      </a-select>
      <a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      <a-input style="width: 120px " disabled v-model="showText"></a-input>

      <a-icon style="width: 20px"  type="edit" class="editable-cell-icon" @click="edit" />
<!--      <a-icon-->
<!--        type="edit"-->
<!--        class="editable-cell-icon-check"-->
<!--        @click="edit"-->
<!--      />-->
    </div>
  </div>
</template>


<script>
export default {
  name: "select",
  props: {
    text: String
  },
  data() {
    return {
      value: "",
      editable: false,
      showText: ""
    };
  },
  watch: {
    text: function href(val, oldVal) {
      console.log('WW',val,oldVal);
      if (val == "T" && val !== null) {
        this.showText = "分销商";
      } else if (val !== "T" && val !== null) {
        this.showText = "代理商";
      } else {
        this.showText = ''
      }
    }
  },
  mounted() {
    // 分销代理判断：原来代码为 this.showText = this.text == "T" ? "分销商" : "代理商"; 修改以下代码
    console.log('this.text',this.text);
    if (this.text == "T" && this.text !== null) {
      this.showText = "分销商";
      this.value = this.text
    } else if (this.text !== "T" && this.text !== null) {
      this.showText = "代理商";
      this.value = this.text
    } else {
       this.showText = ''
      this.value = ''
    }
  },
  methods: {
    handleChange(value) {
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
      this.$emit("edit");
    }
  }
};
</script>
<style lang='less'>
.editable-cell {
  position: relative;
}
.editable-cell-input-wrapper {
  .ant-select-selection {
    width: 125px !important;
  }
}
.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;

}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>