import http from "@/utils/request";

export function terminalActivityByIdFxshop(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fxshop/terminalActivityById.nd",
    data: data
  });
}

// 获取仓库列表
export function getBaseshopList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    // url: "/fast/user/custShopInfoFx/page.nd",
    url: "/fast/user/custShopInfoFxApply/page.nd",
    data: data
  });
}

// 获取仓库合作关系列表
export function getshopCooperateList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getDistributorShopCooperateList.nd",
    data: data
  });
}

export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/user/custShopInfoFxApply/export.nd',
      data:data,
      responseType: 'blob'  
  })
}