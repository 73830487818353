import {
  getList,
  changeSale,
  exportData,
  preview
} from "./api.js";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      arg: {},
      tableData: [],
      columns: [
        {
          label: "合同类型",
          prop: "contractType",
          width: 150,
          ellipsis: true
        },
        {
          label: "合同编码",
          prop: "contractNo",
          width: 150,
          ellipsis: true
        },
        {
          label: "合同名称",
          prop: "contractName",
          width: 150,
          ellipsis: true
        },
        {
          label: "合同状态",
          prop: "status",
          width: 150,
          ellipsis: true
        },
        {
          label: "销售组织",
          prop: "orgNames",
          width: 200,
          ellipsis: true
        },
        {
          label: "代理商",
          prop: "merchantName",
          width: 150,
          ellipsis: true
        },
        {
          label: "分销商",
          prop: "distributorsName",
          width: 150,
          ellipsis: true

        },
        {
          label: "创建时间",
          prop: "createdDateStr",
          width: 150,
          ellipsis: true

        },

        {
          label: "操作",
          prop: "",
          key: "btnGroup",
          slot: "btnGroup",
          fixed: "right",
          width: 80
        }
      ],
      selectedRowKeys: [], // Check here to configure the default column
      selRows: [],
      loading: false,
      tableLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: 0
      },
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      editingKey: "",
      showbutton: false,
      showAllBUtton: true,
      disabled: false,
      orderLoading: false,
      visibleshop: false,
      checked: ""
    };
  },
  props: {
    shopId: {
      type: Number
    }
  },
  watch: {
    shopId: function() {
      this.getMYList();
      this.onshow();
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    }
  },
  mounted() {
    this.getMYList();
    this.onshow();
  },
  methods: {
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function(e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      };
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        // "custId": this.shopId,
        ...this.arg
      };
      exportData(data).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.code == "success") {
            this.$message.success("下载成功，请到下载中心查看!");
          }
        }).catch(err => {
          Util.downloadFile(res.data, "贸易关系列表.xls");
        }).finally(() => {
          this.orderLoading = false;
        });
      }).catch(err => {
        this.orderLoading = false;
      });
    },
    onshow() {
      if (this.shopId == this.$store.state.user.userInfo.customer.id) {
        this.showAllBUtton = true;
      } else {
        this.showAllBUtton = false;
      }
      if (this.$store.state.user.userInfo.account.type == "main" && this.shopId == this.$store.state.user.userInfo
        .customer.id) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    // 点击编辑按钮
    changngStatus() {
      if (this.selRows.length < 1) {
        this.$message.warning("请选择数据!");
        return false;
      } else {
        this.showbutton = true;
      }
    },
    // 取消
    cansel() {
      this.showbutton = false;
    },
    // 确定修改
    edit() {
      if (this.selRows.length < 1) {
        this.$message.warning("请选择数据!");
        return false;
      } else {
        let checked = null;
        if (this.selRows.isDeleted == null || this.selRows.isDeleted == "F") {
          checked = false;
        } else {
          checked = true;
        }
        this.changeCherk(checked, this.selRows, this.selRows.id);
      }
    },
    openwinDow(selRows, checked) {

      this.visibleshop = true;
      selRows.isDeletedText = selRows.isDeletedText2;
      this.checked = checked;
      this.selRows = selRows;
    },
    // 更改开关
    changeCherk() {

      let isOpen = null;
      if (this.selRows.isDeletedText2 == true) {
        isOpen = 1;
      } else {
        isOpen = 0;
      }
      let data = {
        materialCategory: this.selRows.matklCode,
        isOpen: isOpen
      };

      changeSale(data).then(res => {
        let request = JSON.parse(res.data);
        if (request.code == "0") {
          this.$message.success("修改成功");
          this.getMYList();
          this.selRows = [];
          this.showbutton = false;
        } else {
          this.$message.warning("修改失败," + request.msg);
          if (this.selRows.isDeleted == null || this.selRows.isDeleted == "T") {
          } else if (this.selRows.isDeleted == "F") {
            this.selRows.isDeletedText = false;
          }
          this.showbutton = false;
        }
      });
    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record]);
          }
        }
      };
    },
    // 点击按钮进行修改
    onChange(selRows) {
      this.selectedRowKeys = selRows[0].id;
      this.selRows = selRows[0];
    },
    // 查询条件
    searchFun(arg) {
      this.pager.pageNo = 1;
      this.arg = arg[0];
      this.getMYList();
    },
    // 获取列表数据
    async getMYList() {
      this.tableLoading = true;
      const data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        // "custId": this.shopId,
        ...this.arg
      };
      const res = await getList(data);
      this.tableData = res.data.list;

      this.pager.count = res.data.totalRows;
      this.total = res.data.totalRows;

      this.tableLoading = false;
    },
    // 预览
    preview(data) {
      if(data.url) {
        window.open(data.url,"_blank")
      }

      // this.tableLoading = true;
      // preview(data.contractCode).then(res => {
      //   this.tableLoading = false;
      //   if (res.data.code == 0) {
      //     window.open(res.data.url, "_blank");
      //   } else {
      //     this.$message.warning(res.data.msg);
      //   }
      // });
    }
  }
};
