import http from "@/utils/request";
// 获取仓库列表
export function getBaseshopList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/user/custShopInfo/page.nd",
    data: data
  });
}

// 获取仓库合作关系列表
export function getshopCooperateList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getShopCooperateList.nd",
    data: data
  });
}


export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/user/custShopInfo/export.nd',
      data:data,
      responseType: 'blob'  
  })
}
// 获取专卖店授权书
export function getSpecialShopAuthorizationList(data) {
  return http({
    method: "post",
    url: '/custbase/getSpecialShopAuthorizationList.nd',
    data
  })
}
export function downloadAuthorizationFile(data) {
  return http({
    method: "post",
    url: '/custbase/downloadAuthorizationFile.nd',
    // contentType: "application/pdf",
    // type: 'stringfy',
    // responseType: 'blob'
    responseType: "arraybuffer",
    data
  })
}
//