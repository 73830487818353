function fommat(list) {
  let pidStr = 'pId';
  let idStr = 'id';
  let childrenStr = 'child';
  let listOjb = {}; // 用来储存{key: obj}格式的对象
  let treeList = []; // 用来储存最终树形结构数据的数组
  // 将数据变换成{key: obj}格式，方便下面处理数据
  for (let i = 0; i < list.length; i++) {
    listOjb[list[i][idStr]] = list[i]
  }
  for (let j = 0; j < list.length; j++) {
    // 判断父级是否存在
    let haveParent = listOjb[list[j][pidStr]]
    if (haveParent) {
      // 如果有没有父级children字段，就创建一个children字段
      !haveParent[childrenStr] && (haveParent[childrenStr] = [])
      // 在父级里插入子项
      haveParent[childrenStr].push(list[j])
    } else {
      // 如果没有父级直接插入到最外层
      treeList.push(list[j])
    }
  }
  return treeList
}
import { getRoleList, getRolemenuList } from './api.js'
export default {
  data() {
    return {
      tableData: [],
      columns: [
        {
          label: '角色',
          prop: 'name',
          ellipsis: true,
        },
        {
          label: '状态',
          prop: 'djFlag',
          ellipsis: true,
        },
        {
          label: 'code',
          prop: 'code',
          ellipsis: true,
        },

      ],
      tableLoading: false,
      selectedRowKeys: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      roleId: '',
      menuTree: [],
      treeData: [
        {
          name: '权限信息',
          child: [],
          disabled: true,
        },
      ],
      cherkBox: [],
      replaceFields: {
        children: 'child',
        title: 'name',
        key: 'id'
      },
    };
  },
  props: {
    shopId: {
      type: Number,
    }
  },
  watch: {
    shopId: function () {
      this.getData()
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 角色列表数据
    async getData() {
      this.tableLoading = true
      const data = {
        "queryPage": {
          "page": this.pager.pageNo,
          "pageSize": this.pager.pageSize,
          "maxResults": 20000,
          "orderBy": "id asc",
          "export": "false",
          "queryParamList": [
            {
              "title": "是否可见",
              "field": "userShow",
              "type": "string",
              "isvalid": true,
              "logic": "=",
              "value": "1"
            },
            {
              "title": "系统",
              "field": "type",
              "type": "string",
              "isvalid": true,
              "logic": "=",
              "value": "1"
            }
          ]
        }
      }
      const res = await getRoleList(data)
      this.tableData = res.data.content
      this.pager.pageNo = res.data.page
      this.pager.pageSize = res.data.pageSize
      this.pager.count = res.data.totalRows
      this.total = res.data.totalRows
      // // 设置表格唯一id
      if (res.data.content) {
        res.data.content.forEach(it => {
          it.uid = it.id
        })
      }
      this.tableLoading = false
    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record])
          }
        }
      }
    },
    // 点击单选按钮
    onChange(selRows) {
      // selRows 当前选择项的数据 selKeys 当前选择的key 目前赋值为id
      this.selectedRowKeys = selRows[0].id;
      this.roleId = selRows[0].id;
      this.getrolMenu()
    },
    // 获取角色所有权限
    getrolMenu() {
      let data = {
        roleId: this.roleId
      }
      getRolemenuList(data).then(res => {
        this.cherkBox = []
        if (res.status == 200) {
          this.menuTree = res.data
          this.menuTree.forEach(item => {
            item.disabled = true
            if (item.checked == true) {
              this.cherkBox.push(item.id)
            }
          })
          var treedata = fommat(this.menuTree);
          this.treeData[0].child = treedata
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
}; 