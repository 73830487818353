
import http from "@/utils/request";
// 人员列表
export function getRoleList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/getCustBaseRoleDataPage.nd",
    data: data
  });
}


export function getRolemenuList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/getMenuByRoleId.nd",
    data: data
  });
}