<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelName"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
      :confirm-loading="confirmLoading"
    >
      <a-spin :spinning="pageLoadFlag">
          <div class="modal_close" @click="cancle">
            <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
          </div>
          <div class="modal_title_box">
            <span class="modal_title">
              <i class="modal_title_line"></i>修改密码
            </span>
          </div>
          <p style="margin-top:30px;margin-bottom: 12px;font-size: 14px;font-weight: 500;color: #262626;">请为您的账号 {{userCode}} 设置一个新密码</p>
          <a-form-model>
            <div class="long" style="margin-bottom:24px;display: flex;flex-direction: column;text-align: center;justify-content: flex-start">
              <div>
                <span style="width:70px;text-align:right;margin-right:8px;color:#262626;font-size:14px;font-weight: 400"
                >旧密码:</span
                >
                <a-input-password
                  style="width:300px"
                  v-model="beforePassword"
                  placeholder="请输入"
                />
              </div>
              <span
                v-show="yuanmima"
                style="margin-left:-108px;font-size:12px;color: #D9161C;margin-top: 4px"
              >您输入的密码有误！</span
              >
            </div>
            <div class="long" style="margin-bottom:24px;display: flex;flex-direction: column">
              <div>
                <span style="width:70px;text-align:right;margin-right:8px;color:#262626;font-size:14px;font-weight: 400"
                >新密码:</span
                >
                <a-input-password
                  style="width:300px"
                  v-model="newPassword"
                  placeholder="请输入"
                />
              </div>
              <span
                v-if="xinmima"
                style="margin-left:-75px;font-size:12px;color:#D9161C;margin-top: 4px"
              >您输入的密码不符合规则！</span
              >
            </div>
            <div class="long" style="margin-bottom:24px;display: flex;flex-direction: column">
              <div>
                <span style="width:70px;text-align:right;margin-right:8px;color:#262626;font-size:14px;font-weight: 400"
                >确认密码:</span
                >
                <a-input-password
                  style="width:300px"
                  v-model="aginPassword"
                  placeholder="请输入"
                />
              </div>
              <span
                v-if="querenmima"
                style="margin-left:-75px;font-size:12px;color:#D9161C;margin-top: 4px"
              >您输入的两次密码不一致！</span
              >
            </div>
          </a-form-model>
          <span style="width: 447px;color: #AAAAAA;font-size: 12px;font-weight: normal;">{{ `密码规则：密码长度8-20位；密码需同时包含大写字母、小写字母、数字、特殊字符等4种类型；密码不可包含空格、中文；密码不可包含hisense、登录账号；` }}</span>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>

import { comparePassword } from "@/views/basicInformation/components/personnelManagement/api";

export default {
  components: {},
  name:'updatePassword1',
  data() {
    return {
      yuanmima:false,
      xinmima:false,
      querenmima:false,
      beforePassword:'',
      newPassword: "",
      aginPassword: "",
      pageLoadFlag: false,// 整个页面的loading
      confirmLoading: false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    userCode: {
      type: String,
      default: ""
    },

  },

  watch: {
    visible: function (val){
      if(!val) {
        this.userCode = ''
        this.beforePassword = ''
        this.newPassword = ''
        this.aginPassword = ''
      }
    },
    beforePassword: function (){
      this.changepassword()
    },
    newPassword: function() {
      // this.newPassword = this.newPassword.replace(/\s*/g, "")
      // var flag =  /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/
      // let userNameFlag = this.newPassword.indexOf(this.$store.state.user.userInfo.account.userName)!==-1
      // 判断 even 是否包含特殊字符
      if(this.newPassword.length>=8 && this.newPassword.length<20){
        this.xinmima = false
      }else{
        this.xinmima = true
      }
      if(this.aginPassword !== '') {
        if(this.aginPassword == this.newPassword) {
          this.querenmima = false
        } else {
          this.querenmima = true
        }
      }
    },
    aginPassword: function() {
      if(this.aginPassword == this.newPassword) {
        this.querenmima = false
      } else {
        this.querenmima = true
      }
    }
  },
  mounted() {

  },
  methods: {
    // 判断当前密码和密码是否一致
    changepassword(){
      let password = this.$util.passwordEncryPtion(this.beforePassword)
      let data = {
        rowpassword: password
      }
      comparePassword(data).then(res=>{
        if(res.data == false) {
          this.yuanmima = true
        } else{
          this.yuanmima = false
        }
      })
    },


    handleChangespart(e) {

    },
    handleOk() {
      if(!this.beforePassword){
        this.$message.warning('请输入旧密码')
        return
      }
      if(!this.newPassword){
        this.$message.warning('请输入新密码')
        return
      }
      if(!this.aginPassword){
        this.$message.warning('请输入确认密码')
        return
      }
      if( this.yuanmima ||  this.xinmima || this.querenmima) {
        this.$message.warning('请输入正确信息')
        return
      }
      this.confirmLoading = true
      let data = {
        beforePassword:this.beforePassword,
        newPassword:this.newPassword,
        aginPassword:this.aginPassword,
      }
      // this.$emit("update:visible", false);
      this.confirmLoading = false
      this.$emit("cancelReasonHandOk",data );
      this.$forceUpdate()

    },
    cancle() {
      this.$emit("update:visible", false);
      this.$forceUpdate()

    },

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;


        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;

          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 100px;
            margin-top: 24px;

            .lable {
              width: 98px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }

            /deep/ .ant-select-selection {
              width: 300px;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .everyBox:first-child{
            margin-top: 70px;
            margin-bottom: 44px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 12px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }



      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:20px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}

/deep/ .cancelNameYHLY {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        .btnGroup {
          display: flex;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
        }

        display: flex;
        justify-content: center;
        width: 470px;

        .clickBtn {
          cursor: pointer;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 36px;
        margin-top: 12px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 56px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px
        }

        .ant-input {
          width: 300px;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}

/deep/ .cancelNameTips {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        display: flex;
        flex-wrap: wrap;
        width: 418px;

        .clickBtn {
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        margin-top: 12px;

        .cancleZi {
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }

        .ant-input {
          width: 300px;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: -24px;
        right: -114px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


