
import { personList, personList2, MaterialList, MaterialList2, resetPassword, updateStatus, savePermission, editperson, editRowAccount, bindSignature, exportData,canselSignature,queryBindingAccount, comparePassword,updatePassword, exportDataNew } from './api.js'
import { getdictInfo, getdaiabnInfo, getmessageInfo } from "../addPerson/api.js"
import updatePassword1 from "./components/updatePassword1"
import Util from "@/utils/utils";
import sendMessage from "@/views/userOperations/IntentionList/components/sendMessage";
import { env } from "@/common/constant";
export default {
  components: {
    updatePassword1
  },
  data() {
    return {
      arg:{},
      tableData: [],
      columns: [
        {
          label: '账号类型',
          prop: 'typeText',
          width: 150,
          scopedSlots: { customRender: 'typeText' },
          ellipsis: true,
        },
        {
          label: '信天翁账号',
          prop: 'loginAccount',
          width: 200,
          scopedSlots: { customRender: 'loginAccount' },
          ellipsis: true,
        },
        {
          label: '人员姓名',
          prop: 'contactPerson',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'contactPerson' },
        },
        {
          label: '手机号',
          prop: 'contactTel',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'contactTel' },
        },
        {
          label: '岗位',
          prop: 'workNames',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'workNames' },
        },
        {
          label: '邮箱',
          prop: 'email',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'email' },
        },
        {
          label: '状态',
          prop: 'statusText',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'statusText' },
        },
        {
          label: '审批状态',
          prop: 'checkStatus',
          width: 150,
          ellipsis: true,
        },
        {
          label: '法律协议',
          prop: 'legalAgreeStatusText',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'legalAgreeStatusText' },
        },
        {
          label: "操作",
          prop: "",
          fixed: 'right',
          key: "btnGroup",
          slot: "btnGroup",
          width: 260
        }
      ],

      selectedRowKeys: [], // Check here to configure the default column
      loading: false,

      materialGroupGroup: [],// 物料组
      roleList: [],// 角色
      shopCkList: [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ], // 管理的仓库
      shopList: [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ], // 管理的门店
      isActive: 0,
      isActiveLevel: 0,
      ck: undefined,
      shop: undefined,
      contacts: '', //联系人
      contactsPhone: '', // 联系电话
      email: '', // 邮箱
      content: '',
      title: '',
      problemLevel: 0, // 问题级别
      problemType: '', // 问题类型
      fileList: [],
      message: '',
      onluyOneMessage: false,
      previewVisible: false,
      previewImage: '',
      radio: 'radio',
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 0
      },
      showBottom: false,
      tableLoading: false,
      partnerName: '',
      personId: '',
      selRows: [],
      pageLoadFlag: false,
      visible: false,
      selectShop: [],
      selectShopName: [],
      StoreItem: [],
      StoreName: [],
      edit: true,
      canChange: true,
      canselect: true,
      materialGroup: [],// 物料组选中数据
      roleGroup: [],// 角色选中数据
      shopSelect: [],
      storeSelect: [],
      MaterLength: '',
      contactvalue: '',
      Personvalue: '',
      emailValue: '',
      showbutton: true,
      buttonDisAbled: true,
      showAllBUtton: true,
      loseButton: true,
      nocticeList: [],// 通知列表
      isActiveNoticeLevel: 0,
      NoticeLevel: 0,
      todoList: [],// 通知列表
      isActivetodoLevel: 0,
      todoLevel: 0,
      positionList: [], // 岗位数据
      isActivePLevel: 0,
      positinLevel: 0,
      orderLoading: false,
      canselSign:false,
      signMessage:'',
      canselSignButton:false,
      updatevisible:false,
      confirmLoading:false,
      beforePassword: "",
      newPassword: "",
      aginPassword: "",
      yuanmima:false,
      xinmima:false,
      querenmima:false,
      passwordId:'',
      account11Id:'',
      logoffShow: false,//注销弹窗
      phone: '',
      userCode:''
    };
  },
  props: {
    shopId: {
      type: Number,
    },
    accountId: {
      type: Number
    }
  },
  watch: {
    accountId: function () {
      // this.getPersonList()
    },
    shopId: function () {
      // this.getPersonList()
      this.partnerName = ''
      this.materialGroup = []
      this.roleGroup = []
      this.selectShop = []
      this.StoreItem = []
      this.materialGroupGroup = []
      this.roleList = []
      this.shopCkList = []
      this.shopList = []
      this.selectShopName = []
      this.StoreName = []
      this.personId = ''
      this.onshow()
    },
    beforePassword: function (){
     this.changepassword()
    },
    newPassword: function() {
        // var flag =  /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/
        // // 判断 even 是否包含特殊字符
        // if(flag.test(this.newPassword)){
        //   this.xinmima = false
        // }else{
        //   this.xinmima = true
        // }
      if(this.newPassword.length < 8 || this.newPassword.length > 20){
        this.xinmima = true
      }else{
        this.xinmima = false
      }
        if(this.aginPassword !== '') {
          if(this.aginPassword == this.newPassword) {
            this.querenmima = false
          } else {
            this.querenmima = true
          }
        } 
    },
    aginPassword: function() {
      if(this.aginPassword == this.newPassword) {
        this.querenmima = false
      } else {
        this.querenmima = true
      }
    }
    
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {

   this.account11Id = this.$store.state.user.userInfo.account.account
    // this.getPersonList()
    if (this.$store.state.user.userInfo.account.type == 'main') {
      // this.getPersonList()
      this.showAllBUtton = true

    } else {
      // this.getPersonList()
      this.showAllBUtton = false
    }
  },
  methods: {
    // 判断当前密码和密码是否一致
    changepassword(){
      let password = this.$util.passwordEncryPtion(this.beforePassword)
      let data = {
        rowpassword: password
      }
      comparePassword(data).then(res=>{

        if(res.data == false) {
          this.yuanmima = true
        } else{
          this.yuanmima = false
        }
      })
    },
    // 修改密码
    updatePassword(row) {
      this.passwordId = row.id
      this.userCode = row.loginAccount
      this.updatevisible = true
    },
    handleOk(data) {
      // 密码加密
      let beforePassword = this.$util.passwordEncryPtion(data.beforePassword)
      let newPassword = this.$util.passwordEncryPtion(data.newPassword)
      let aginPassword = this.$util.passwordEncryPtion(data.aginPassword)
      let dataa = {
        rowpassword: beforePassword,
        password: newPassword,
        npassword: aginPassword,
        custAccountId: this.passwordId,
        zt: 2
      }
      updatePassword (dataa).then(res=>{
        this.$message.info(res.data=='success_pwd'?'修改成功':res.data)
        if(res.data=='success_pwd') {
          this.updatevisible = false
        }
      })
    },
    handleCancel() {
      this.updatevisible = false
    },
    showSign(row) {
      if (!row.mdmCode) {
        this.$message.warning("MDM编码不存在")
        return false;
      }

      if(row.id) {
        let data  = {
          custAccountId: row.id,
          account: row.loginAccount,
          mdnCode: row.mdmCode
        }

        queryBindingAccount(data).then(res=>{
          if(res.data.k == '1'){
            this.$message.warning('操作失败,'+res.data.v)
          } else if (res.data.k == 'U-903' || res.data.k == 'U-190002') {
            this.$message.warning('操作失败,'+res.data.v)
          } else if (res.data.k == '0') {
            this.signMessage = `该MDM编码绑定的上上签账号为${row.contactTel}，是否确认执行解绑?`
            this.canselSign = true
            this.selRows = row
          }
         
        })

      }
      
    },
    oncanselSign() {
      let data = {
        custAccountId: this.selRows.id,
        account: this.selRows.loginAccount,
        mdnCode: this.selRows.mdmCode
      }
      canselSignature(data).then(res => {
        if (res.data.k == '0') {
          this.$message.success(res.data.v)
          this.getPersonList()
          this.canselSignButton = false
        } else {
          this.$message.warning('解绑失败,'+res.data.v)
        }
      })
    },
    // 表格方法 如果为true 就可以勾选 为false就不能
    checkSelectable(row) {
      return row.typeText == '用户账号'
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
         
        "pageNo": this.pager.pageNo,
        "orderBy": "t.type asc",
        "pageSize": this.pager.pageSize,
        "custId": this.accountId,
        // "queryParamList": []
        ...this.arg,

        isSegment: '1'// 新增参数 是否分段导出 1是0否
     
    }
    exportDataNew(data).then(res => {
      this.orderLoading = false;
      if (res && res.data && res.data.msg == 'success') {
        this.$message.success('下载成功，请到下载中心查看!')
      }
        // Util.blobToJson(res.data).then(content => {
        //   debugger
        //   if (content && content.code == 'success') {
        //     this.$message.success('下载成功，请到下载中心查看!')
        //   }
        // }).catch(err => {
        //   Util.downloadFile(res.data, '人员列表.xls')
        // }).finally(() => {
        //   this.orderLoading = false;
        // })
      }).catch(err => {
        this.orderLoading = false;
      });
    },
    // 获取岗位
    getPosition() {
      getdictInfo().then(res => {
        res.data.list.forEach(item => {
          item.isCheck = item.isCheck === 'true'
        })
        this.positionList.push(...res.data.list)
      })
    },
    // 获取待办 
    getTodo() {
      getdaiabnInfo().then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(item => {
            item.isCheck = item.isCheck === 'true'
          })
          this.todoList.push(...res.data.list)
        } else {
          this.todoList = []
        }
      })
    },
    // 获取通知选项
    getNotice() {
      getmessageInfo().then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(item => {
            item.isCheck = item.isCheck === 'true'
          })
          this.nocticeList.push(...res.data.list)
        } else {
          this.nocticeList = []
        }
      })
    },
    // 通知选择
    btnnoticeClickLevel(index, id, item) {
      this.isActiveNoticeLevel = index
      this.NoticeLevel = id
      item.isCheck = !item.isCheck
      if (index == 0 && item.isCheck == true) {
        this.nocticeList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.nocticeList.forEach(item => {
          item.isCheck = false
        })
      }
    },
    // 待办选择
    btnTodoClickLevel(index, id, item) {
      this.isActivetodoLevel = index
      this.todoLevel = id
      item.isCheck = !item.isCheck
      if (index == 0 && item.isCheck == true) {
        this.todoList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.todoList.forEach(item => {
          item.isCheck = false
        })
      }
    },
    // 岗位选择
    btnPostionClickLevel(index, id, item) {
      this.isActivePLevel = index
      this.positinLevel = id
      item.isCheck = !item.isCheck
      if (index == 0 && item.isCheck == true) {
        this.positionList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.positionList.forEach(item => {
          item.isCheck = false
        })
      }
    },
    // 激活账号
    active(row) {
      let data = {
        id: row.id,
        status: 'active'
      }
      updateStatus(data).then(res => {
        this.$message.info(res.data == 'success' ? '已生效' : res.data)
        this.loseButton = true
        this.getPersonList()

      }).catch(error => {
        console.log(error)
      })

    },
    // 控制按钮显示隐藏
    onshow() {
      if (this.shopId == this.$store.state.user.userInfo.customer.id) {
        this.showAllBUtton = true
      } else {
        this.showAllBUtton = false
      }
    },
    // signature签章
    signature(row) {
      let data = {
        custAccountId: row.id,
        account: row.loginAccount,
        mdnCode: row.mdmCode
      }
      bindSignature(data).then(res => {
        if (res.data.k == '0') {
          window.open(res.data.v, "_blank");
          this.canselSignButton = true
        } else {
          this.$message.warning('签章失败')
        }
      })
    },
    // 清空门店
    clearStoreItem() {
      this.StoreItem = []
      this.StoreName = null
    },
    clearshop() {
      this.selectShop = []
      this.selectShopName = null
    },
    // 跳转新增页面
    gotoadd() {
      this.$router.push({ path: '/addPerson', query: { shopId: this.shopId } });
    },
    confirm() {
      let name = ''
      let phone = ''
      let email = ''
      if (!this.contactvalue) {
        phone = this.selRows.contactTel
      } else {
        phone = this.contactvalue
      }
      if (!this.Personvalue) {
        name = this.selRows.contactPerson
      } else {
        name = this.Personvalue
      }
      if (!this.emailValue) {
        email = this.selRows.email
      } else {
        email = this.emailValue
      }
      let data = {
        userName: name,
        phone: phone,
        email: email,
        custAccountId: this.selRows.id
      }
      editRowAccount(data).then(res => {
        if (res.data.code == 'success') {
          this.$message.info(res.data.msg)
          this.materialGroupGroup = []
          this.roleList = []
          this.selectShop = []
          this.selectShopName = []
          this.StoreItem = []
          this.StoreName = []
          this.shopCkList = []
          this.shopList = []
        } else {
          this.$message.warning(res.data.msg)
          this.materialGroupGroup = []
          this.roleList = []
          this.selectShop = []
          this.selectShopName = []
          this.StoreItem = []
          this.StoreName = []
          this.shopCkList = []
          this.shopList = []
        }
        this.showbutton = true
      })
    },
    handleChange(value, record, column) {
      if (column == 'contactTel') {
        this.contactvalue = value
      }
      if (column == 'contactPerson') {
        this.Personvalue = value
      }
      if (column == 'email') {
        this.emailValue = value
      }
      const newData = [...this.tableData];
      const target = newData.filter(item => record.id === item.key)[0];
      if (target) {
        this.tableData = newData;

      }
    },
    goFlow(feed) {
      window.open(
        `/auth-api/displayFlow?param=${feed.processInstId}#/`
      );
    },

    // 编辑表格
    editTble(row) {
      if (row.activityInstId){
        this.$message.warning('该账号正在审批中，流程尚未结束，暂不能编辑')
        return
      }
      this.$router.push({
        path: '/editPerson', query: {
          id: row.id,
          shopId: this.shopId,
          type: row.typeText,
          loginAccount: row.loginAccount,
          phone: row.contactTel,
          email: row.email,
          name: row.contactPerson,
          accountType:row.accountType
        }
      });
    },
    changeEdit() {
      this.getQXlist()
      this.edit = false
      this.canChange = false
      this.canselect = false
    },
    //选中后的值
    onChangeValue(value) {
      this.selectShop = this.checkAll(value, this.shopCkList)
      this.selectShopName = []
      this.shopCkList.forEach(shopItem => {
        this.selectShop.forEach(item => {
          if (shopItem.id == item) {
            this.selectShopName.push(shopItem)
          }
        })
      })
    },
    // 点击全选触发
    checkAll(arr, modelList) {
      // arr:change中的数组 ，  modelList:下拉框List
      let length = arr.length
      let list = arr
      arr.forEach(element => {
        // 当数组中存在0，说明此时进行全选/取消全选
        if (element == '0') {
          // 当数组长度为最大长度且最后一个元素为0时，说明此时在全选的基础上又点击全选，则取消全选
          if (length - 1 == modelList.length && arr[0] == '0') {
            list = []
          } else {
            // 当不是取消全选操作，只要数组中出现了0则说明进行了全选操作
            list = []
            for (let i in modelList) {
              if (modelList[i].id !== 0) {
                list.push(modelList[i].id)
              }
            }
          }
        }
      })
      return list
    },
    onChangeValuestore(value) {
      this.StoreItem = this.checmdkAll(value, this.shopList)
      this.StoreName = []
      this.shopList.forEach(shopItem => {
        this.StoreItem.forEach(item => {
          if (shopItem.id == item) {
            this.StoreName.push(shopItem)
          }
        })
      })
    },
    checmdkAll(arr, modelList) {
      // arr:change中的数组 ，  modelList:下拉框List
      let length = arr.length
      let list = arr
      arr.forEach(element => {
        // 当数组中存在0，说明此时进行全选/取消全选
        if (element == '0') {
          // 当数组长度为最大长度且最后一个元素为0时，说明此时在全选的基础上又点击全选，则取消全选
          if (length - 1 == modelList.length && arr[0] == '0') {
            list = []
          } else {
            // 当不是取消全选操作，只要数组中出现了0则说明进行了全选操作
            list = []
            for (let i in modelList) {
              if (modelList[i].id !== 0) {
                list.push(modelList[i].id)
              }
            }
          }
        }
      })
      return list
    },
    // 按钮点击样式切换
    btnClickLevel(index, id, item) {
      this.isActiveLevel = index
      this.problemLevel = id
      item.isCheck = !item.isCheck
      if (index == 0 && item.isCheck == true) {
        this.materialGroupGroup.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.materialGroupGroup.forEach(item => {
          item.isCheck = false
        })
      }
    },
    // 修改权限
    confim() {
      this.materialGroup = []
      this.roleGroup = []
      let noticeCherk = []
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.materialGroup.push(item.id)
        }
      })
      this.roleList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.roleGroup.push(item.id)
        }
      })
      this.nocticeList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          noticeCherk.push(item.id)
        }
      })
      let tocoCherk = []
      this.todoList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          tocoCherk.push(item.id)
        }
      })
      let positionCherk = []
      this.positionList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          positionCherk.push(item.id)
        }
      })
      let data = {
        "custId": this.shopId,//主账号商家id
        "custAccountId": this.personId,//选中账号id
        "operateType": "edit",//操作类型 add 新增，edit 编辑
        "matklIds": this.materialGroup, //  //物料ids
        "roleIds": this.roleGroup,//角色ids
        "wareHouseIds": this.selectShop, // 仓库id
        "shopIds": this.StoreItem,// 门店id
        "workIds": positionCherk,
        "noticeIds": noticeCherk,
        "dealIds": tocoCherk,
      }
      savePermission(data).then(res => {
        if (res.status == 200) {
          this.$message.success('修改成功');
          this.edit = true
          this.canChange = true
          this.canselect = true
          this.getMorenList()
        } else {
          this.$message.warning('修改失败');
          this.edit = true
          this.canChange = true
          this.canselect = true
          this.getMorenList()
        }

      })
    },
    // cacelQxuan 取消修改权限
    cacelQxuan() {
      this.edit = true
      this.canChange = true
      this.canselect = true
      this.getMorenList()
    },
    btnClick(index, id, item) {
      this.isActive = index
      this.problemType = id
      item.isCheck = !item.isCheck
      if (index == 0 && item.isCheck == true) {
        this.roleList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.roleList.forEach(item => {
          item.isCheck = false
        })
      }
    },
    searchFun(arg) {

      this.pager.pageNo = 1
      
      this.arg = arg[0]
      this.getPersonList();
    },
    async getPersonList() {
      this.tableLoading = true
      if (this.$store.state.user.userInfo.account.type == 'main') {
        const data = {
         
            "pageNo": this.pager.pageNo,
            "orderBy": "t.type asc,t.account asc ",
            "pageSize": this.pager.pageSize,
            "custId": this.shopId,
            // "queryParamList": []
            ...this.arg
         
        }
        const res = await personList(data)
        this.tableData = res.data.list
        // this.pager.pageNo = res.data.page
        // this.pager.pageSize = res.data.pageSize
        this.pager.count = res.data.totalRows
        this.total = res.data.totalRows
        this.tableLoading = false
      } else {
        // const data = {
        //   queryPage: {
        //     "page": this.pager.pageNo,
        //     "orderBy": "t.type asc",
        //     "pageSize": this.pager.pageSize,
        //     "custAccountId": this.accountId,
        //     "queryParamList": []
        //   },
        //   custAccountId: this.accountId,
        // }
        const data = {
         
          "pageNo": this.pager.pageNo,
          "orderBy": "t.type asc",
          "pageSize": this.pager.pageSize,
          "custId": this.accountId,
          // "queryParamList": []
          ...this.arg
       
      }
        const res = await personList(data)

        this.tableData = res.data.list
        this.pager.count = res.data.totalRows 
        this.total = res.data.totalRows 
        // // 设置表格唯一id
        if (res.data.list) {
          res.data.list.forEach(it => {
            it.uid = it.id
            it.editable = false
          })
        }
        this.tableLoading = false
      }
    },

    // 权限数据接口对接
    async getQXlist() {
      this.pageLoadFlag = true
      this.materialGroupGroup = []
      this.roleList = []
      this.selectShop = []
      this.selectShopName = []
      this.StoreItem = []
      this.StoreName = []
      this.shopCkList = [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ]
      this.shopList = [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ]
      const data = {
        queryPage: {
          "page": this.pager.pageNo,
          "pageSize": this.pager.pageSize,
          "custId": this.shopId,
          "queryParamList": [
            { "title": "门店id", "field": "t.custShopInfo.id", "type": "string", "isvalid": true, "logic": "=", "value": this.shopId }
          ]
        },
        custId: this.shopId,
        custAccountId: this.personId,
      }
      const res = await MaterialList(data)
      if (res.data.data.baseMatklList.length > 0) {
        this.materialGroupGroup = [{
          id: 0,
          name: '全部',
          isCheck: false
        }]
        res.data.data.baseMatklList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
        })
        this.materialGroupGroup.push(...res.data.data.baseMatklList)
      }
      // 待办
      if (res.data.data.dealList.length > 0) {
        this.todoList = [{
          id: 0,
          name: '全部',
          isCheck: false
        }]
        res.data.data.dealList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
        })
        this.todoList.push(...res.data.data.dealList)
      }
      // 公告
      if (res.data.data.noticeList.length > 0) {
        this.nocticeList = [{
          id: 0,
          name: '全部',
          isCheck: false
        }]
        res.data.data.noticeList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
        })
        this.nocticeList.push(...res.data.data.noticeList)
      }
      // 职务
      if (res.data.data.workList.length > 0) {
        this.positionList = [{
          id: 0,
          name: '全部',
          isCheck: false
        }]
        res.data.data.workList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
        })
        this.positionList.push(...res.data.data.workList)
      }

      if (res.data.data.roleList.length > 0) {
        res.data.data.roleList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
        })
        this.roleList = res.data.data.roleList
        this.roleList = [{
          id: 0,
          name: '全部',
          isCheck: false
        }]
        this.roleList.push(...res.data.data.roleList)
      }

      this.shopCkList.push(...res.data.data.shopCkList)
      res.data.data.shopCkList.forEach(shopItem => {
        if (shopItem.isCheck == 'true') {
          this.selectShop.push(shopItem.id)
          this.selectShopName.push(shopItem)
        }
      })
      this.shopList.push(...res.data.data.shopList)
      res.data.data.shopList.forEach(StoreItem => {
        if (StoreItem.isCheck == 'true') {
          this.StoreItem.push(StoreItem.id)
          this.StoreName.push(StoreItem)
        }
      })
      this.pageLoadFlag = false
    },
    // 获取默认数据
    async getMorenList() {
      this.pageLoadFlag = true
      this.todoList = []
      this.nocticeList = []
      this.positionList = []
      this.materialGroupGroup = []
      this.roleList = []
      this.selectShop = []
      this.selectShopName = []
      this.StoreItem = []
      this.StoreName = []
      this.shopCkList = []
      this.shopList = []
      if (this.$store.state.user.userInfo.account.type == 'main') {
        const data = {
          queryPage: {
            "page": this.pager.pageNo,
            "pageSize": this.pager.pageSize,
            "custId": this.shopId,
            "queryParamList": [
              { "title": "门店id", "field": "t.custShopInfo.id", "type": "string", "isvalid": true, "logic": "=", "value": this.shopId }
            ]
          },
          custId: this.shopId,
          custAccountId: this.personId,
        }
        const res = await MaterialList(data)
        res.data.data.baseMatklList.forEach(item => {
          if (item.isCheck == 'true') {
            item.isCheck = item.isCheck === 'true'
            this.materialGroupGroup.push(item)
          }
        })
        res.data.data.roleList.forEach(item => {
          if (item.isCheck == 'true') {
            item.isCheck = item.isCheck === 'true'
            this.roleList.push(item)
          }
        })
        // 待办
        if (res.data.data.dealList) {
          res.data.data.dealList.forEach(item => {
            if (item.isCheck == 'true') {
              item.isCheck = item.isCheck === 'true'
              this.todoList.push(item)
            }
          })
        } else {
          this.todoList = []
        }
        // 公告
        if (res.data.data.noticeList) {
          res.data.data.noticeList.forEach(item => {
            if (item.isCheck == 'true') {
              item.isCheck = item.isCheck === 'true'
              this.nocticeList.push(item)
            }
          })
        } else {
          this.nocticeList = []
        }
        // 职务
        if (res.data.data.workList) {
          res.data.data.workList.forEach(item => {
            if (item.isCheck == 'true') {
              item.isCheck = item.isCheck === 'true'
              this.positionList.push(item)
            }
          })
        } else {
          this.positionList = []
        }
        this.shopCkList = res.data.data.shopCkList
        res.data.data.shopCkList.forEach(shopItem => {
          if (shopItem.isCheck == 'true') {
            this.selectShop.push(shopItem.id)
            this.selectShopName.push(shopItem)
          }
        })
        this.shopList = res.data.data.shopList
        res.data.data.shopList.forEach(StoreItem => {
          if (StoreItem.isCheck == 'true') {
            this.StoreItem.push(StoreItem.id)
            this.StoreName.push(StoreItem)
          }
        })
        // if (this.selRows.typeText == '用户账号') {
        //   res.data.data.baseMatklList.forEach(item => {
        //     if (item.isCheck == 'true') {
        //       item.isCheck = item.isCheck === 'true'
        //       this.materialGroupGroup.push(item)
        //     }
        //   })
        //   res.data.data.roleList.forEach(item => {
        //     if (item.isCheck == 'true') {
        //       item.isCheck = item.isCheck === 'true'
        //       this.roleList.push(item)
        //     }
        //   })
        //   // 待办
        //   if (res.data.data.dealList) {
        //     res.data.data.dealList.forEach(item => {
        //       if (item.isCheck == 'true') {
        //         item.isCheck = item.isCheck === 'true'
        //         this.todoList.push(item)
        //       }
        //     })
        //   } else {
        //     this.todoList = []
        //   }
        //   // 公告
        //   if (res.data.data.noticeList) {
        //     res.data.data.noticeList.forEach(item => {
        //       if (item.isCheck == 'true') {
        //         item.isCheck = item.isCheck === 'true'
        //         this.nocticeList.push(item)
        //       }
        //     })
        //   } else {
        //     this.nocticeList = []
        //   }
        //   // 职务
        //   if (res.data.data.workList) {
        //     res.data.data.workList.forEach(item => {
        //       if (item.isCheck == 'true') {
        //         item.isCheck = item.isCheck === 'true'
        //         this.positionList.push(item)
        //       }
        //     })
        //   } else {
        //     this.positionList = []
        //   }
        //   this.shopCkList = res.data.data.shopCkList
        //   res.data.data.shopCkList.forEach(shopItem => {
        //     if (shopItem.isCheck == 'true') {
        //       this.selectShop.push(shopItem.id)
        //       this.selectShopName.push(shopItem)
        //     }
        //   })
        //   this.shopList = res.data.data.shopList
        //   res.data.data.shopList.forEach(StoreItem => {
        //     if (StoreItem.isCheck == 'true') {
        //       this.StoreItem.push(StoreItem.id)
        //       this.StoreName.push(StoreItem)
        //     }
        //   })
        // } else {
        //   res.data.data.baseMatklList.forEach(item => {
        //     item.isCheck = true
        //     this.materialGroupGroup.push(item)
        //   })
        //   res.data.data.roleList.forEach(item => {
        //     item.isCheck = true
        //     this.roleList.push(item)
        //   })
        //   // 待办
        //   if (res.data.data.dealList) {
        //     res.data.data.dealList.forEach(item => {
        //       item.isCheck = true
        //       this.todoList.push(item)
        //     })
        //   }
        //   // 公告
        //   if (res.data.data.noticeList) {
        //     res.data.data.noticeList.forEach(item => {
        //       item.isCheck = true
        //       this.nocticeList.push(item)
        //     })
        //   }
        //   // 职务
        //   if (res.data.data.workList) {
        //     res.data.data.workList.forEach(item => {
        //       item.isCheck = true
        //       this.positionList.push(item)
        //     })
        //   }
        //   this.shopCkList = res.data.data.shopCkList
        //   res.data.data.shopCkList.forEach(shopItem => {
        //     this.selectShop.push(shopItem.id)
        //     this.selectShopName.push(shopItem)
        //   })
        //   this.shopList = res.data.data.shopList
        //   res.data.data.shopList.forEach(StoreItem => {
        //     this.StoreItem.push(StoreItem.id)
        //     this.StoreName.push(StoreItem)
        //   })
        // }
      } else {
        const data = {
          queryPage: {
            "page": this.pager.pageNo,
            "pageSize": this.pager.pageSize,
            "custId": this.shopId,
            "queryParamList": [
              { "title": "门店id", "field": "t.custShopInfo.id", "type": "string", "isvalid": true, "logic": "=", "value": this.shopId }
            ]
          },
          custId: this.shopId,
          custAccountId: this.personId,
        }
        const res = await MaterialList2(data)
        if (this.selRows.typeText == '用户账号') {
          if (res.data.data.baseMatklList.length > 0) {
            this.materialGroupGroup = res.data.data.baseMatklList
            this.materialGroupGroup.forEach(item => {
              item.isCheck = true
            })
          } else {
            this.materialGroupGroup = []
          }
          if (res.data.data.roleList.length > 0) {
            this.roleList = res.data.data.roleList
            this.roleList.forEach(item => {
              item.isCheck = true
            })
          } else {
            this.roleList = []
          }
          this.shopCkList = res.data.data.shopCkList
          res.data.data.shopCkList.forEach(shopItem => {
            this.selectShop.push(shopItem.id)
            this.selectShopName.push(shopItem)
          })
          this.shopList = res.data.data.shopList
          res.data.data.shopList.forEach(StoreItem => {
            this.StoreItem.push(StoreItem.id)
            this.StoreName.push(StoreItem)
          })
          this.todoList = res.data.data.dealList
          this.todoList.forEach(item => {
            item.isCheck = true
          })
          this.nocticeList = res.data.data.noticeList
          this.nocticeList.forEach(item => {
            item.isCheck = true
          })
          this.positionList = res.data.data.workList
          this.positionList.forEach(item => {
            item.isCheck = true
          })
        } else {
          res.data.data.baseMatklList.forEach(item => {
            item.isCheck = true
            this.materialGroupGroup.push(item)
          })
          res.data.data.roleList.forEach(item => {
            item.isCheck = true
            this.roleList.push(item)
          })
          // 待办
          if (res.data.data.dealList) {
            res.data.data.dealList.forEach(item => {
              item.isCheck = true
              this.todoList.push(item)
            })
          }
          // 公告
          if (res.data.data.noticeList) {
            res.data.data.noticeList.forEach(item => {
              item.isCheck = true
              this.nocticeList.push(item)
            })
          }
          // 职务
          if (res.data.data.workList) {
            res.data.data.workList.forEach(item => {
              item.isCheck = true
              this.positionList.push(item)
            })
          }
          this.shopCkList = res.data.data.shopCkList
          res.data.data.shopCkList.forEach(shopItem => {
            this.selectShop.push(shopItem.id)
            this.selectShopName.push(shopItem)
          })
          this.shopList = res.data.data.shopList
          res.data.data.shopList.forEach(StoreItem => {
            this.StoreItem.push(StoreItem.id)
            this.StoreName.push(StoreItem)
          })
        }
      }
      this.pageLoadFlag = false
    },
    // 选择项更改
    onChange(selRows) {

      let that = this
      this.selectedRowKeys = selRows[0].id;
      this.selRows = selRows[0]
      this.personId = this.selRows.id
      this.partnerName = selRows[0].loginAccount + '-' + selRows[0].contactPerson
      this.getMorenList()
      this.showBottom = true

    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record])
          }
        }
      }
    },
    // 重置密码
    resetPassword(row) {

      let data = {
        id: row.id,
        status: ''
      }
      resetPassword(data).then(res => {
        if (res.data == 'success_byEmail') {
          this.visible = true
        } else {
          this.$message.warning(res.data)
        }
      }).catch(error => {
        console.log(error)
      })

    },
    // 失效账号
    lose(row) {

      let data = {
        id: row.id,
        status: 'inActive'
      }
      updateStatus(data).then(res => {
        this.$message.info(res.data == 'success' ? '已失效' : res.data)
        this.loseButton = false
        this.getPersonList()

      }).catch(error => {
        console.log(error)
      })
    },
    // canselChange 修改账号取消
    canselChange() {
      this.showbutton = true
      const newData = [...this.tableData];
      const target = newData.filter(item => this.selRows.id === item.id)[0];
      this.editingKey = this.selRows.id;
      if (target) {
        target.editable = false;
        this.tableData = newData;
      }
    },
    // 注销
    logOff(info) {
        if(info.accountType == 'admin') {
            this.$message.warning('如您要注销账号请联系分公司。');
            return;
        }
        this.phone = info.contactTel
        this.logoffShow = true;
    },
    closeLogoff(e) {
        this.logoffShow = e;
    }
  },
};