/*
 * @Author: your name
 * @Date: 2021-10-27 17:29:27
 * @LastEditTime: 2021-10-29 13:50:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\basicInformation\components\sinkManagement\api.js
 */
import http from "@/utils/request";


export function fxShopCkList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/user/fxShopCkList/page.nd",
    data: data
  });
}
// 获取仓库合作关系列表
export function getshopCooperateList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getShopCooperateList.nd",
    data: data
  });
}
// 导出
export function exportData(data) {
  return http({
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    method: "post",
    url: '/fast/user/fxShopCkList/export.nd',
    data:data,
    responseType: 'blob'
  })
}
export function terminalActivityById(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/custShopInfoCk/terminalActivityById.nd",
    data: data
  });
}

