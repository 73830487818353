<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelName"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>专属服务商
        </span>
      </div>

      <div class="contentBox">
        <p >{{name}}</p>
        <div class="tableForm" v-if="listData.length>0">
          <div class="everyBox"
            v-for="item in listData"
            :key="item.id"
          >
            <p class="name">{{item.serviceProviderName}}</p>
            <div class="oneBox">
              <p
                v-for="(itemChild,itemChildIndex) in item.storeServiceProviderManagerList "
                :key="itemChildIndex"
              >{{ itemChild.serviceProviderManagerName }}-<span>{{ itemChild.serviceProviderManagerPhone }}</span> </p>
            </div>
            <div class="twoBox"
              v-for="(npsChild,itemProIndex)  in  item.storeServiceProviderNpsList "
               :key="itemProIndex"
            >
              <p class="pl">服务厂商：{{npsChild.serviceProviderManufacturer}}</p>
              <div class="right">
                <p style="color: #AAAAAA">口碑(NPS)：{{Math.trunc(Number(npsChild.serviceProviderNps))}}分</p>
                <div class="star-list">
                  <p v-for ="(items,index) in 5">
                    <img v-if =" ((index + 1) * 2) <= Math.floor(Number(npsChild.serviceProviderNps) / 10)" class="star-image" src="@/assets/order/已收藏-一半.png"/>
                    <img v-else-if =" index * 2 < Math.floor(Number(npsChild.serviceProviderNps) / 10)" class="star-image" src="@/assets/order/已收藏.png"/>
                    <img v-else class="star-image" src="@/assets/order/已收藏灰色2.png" />
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="noneBox" v-if="listData.length<=0">
          <img :src="nolistImg" alt="">
          <p class="none">暂无专属服务商信息~</p>
        </div>
      </div>
    </a-modal>

  </div>

</template>

<script>

import { findProviderList } from "@/views/basicInformation/api";

export default {
  components:{
  },
  name: "serviceProvider",
  data() {
    return {
      nolistImg: require("@/assets/order/none.png"),
      listData:[]


    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shopId: {
      type: Number,
    },
    ckId: {
      type: Number,
    },
    name: {
      type: String,
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        if(newValue){
          this.getList()
        }else{
          this.ckId=''
          this.listData = []

        }
      }
    },

  },
  mounted() {
  },
  methods: {
    // 获取服务商
    getList(){
      let data = {
        storeId:this.ckId
        // this.ckId
      }
      findProviderList(data).then(res=>{
        if(res.data.code == 0) {
          this.listData = res.data.data
        }else {
          this.listData = []
        }
      }).catch(error=>{
        this.listData = []
      })
    },

    handleOk() {
      this.$emit("update:visible", false);

    },
    cancle() {
      this.$emit("update:visible", false);

    },



  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;



      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 40px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .contentBox {
        height: 400px;
        overflow-y: auto;
        .tableForm {
          width: 600px;
          .everyBox {
            width: 600px;
            //height: 140px;
            background: linear-gradient(90deg, #EEF9F9 0%, #FBFBFB 100%);
            border-radius: 8px;
            margin-bottom: 12px;
            padding: 12px 16px;
            .name {
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
            }
            .oneBox,.twoBox {
              p {
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 17px;
              }
            }
            .oneBox {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              flex-wrap: wrap;
              p{
                margin-right: 24px;
                span {
                  color: #00AAA6;
                }
              }
              p:last-child{
                margin-right: 0px;
              }
            }
            .twoBox {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .pl{
                width: 300px;
              }
              .right {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                p{
                  font-size: 12px;
                  font-weight: 400;
                  color: #777777;
                  line-height: 17px;
                }
                .star-list{
                  height: 24px;
                  margin-left: 6px;
                  display: flex;
                  align-items: center;
                  .star-image{
                    width: 14px;
                    height: 14px;
                    margin-right: 4px;
                  }
                }
              }
            }
          }

        }
        .noneBox{
          display:flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 600px;
          height: 253px;
          .none {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
          }
          img{
            width: 150px;
            height: 121px;
          }
        }
      }

    }

    p {
      font-size: 18px;
      text-align: left;
      font-weight: 500;
      color: #262626;
      margin-bottom: 5px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:8px 0 18px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


