import {
  personnelRecordsManagement,
  exportData,
  deleteItemC,
  dimission,
} from "./api.js";
import Util from "@/utils/utils";
export default {
  data() {
    return {
      arg: {},
      tableData: [],
      columns: [
        {
          label: "业务档案编号",
          prop: "businessFileCode",
          width: 150,
          scopedSlots: { customRender: "businessFileCode" },
          ellipsis: true,
        },
        {
          label: "信天翁账号",
          prop: "custAccountName",
          width: 200,
          scopedSlots: { customRender: "custAccountName" },
          ellipsis: true,
        },
        {
          label: "姓名",
          prop: "name",
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: "name" },
        },
        {
          label: "岗位",
          prop: "jobsName",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "jobsName" },
        },
        {
          label: "最高学历是否全日制",
          prop: "fullTimeEducation",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "fullTimeEducation" },
          slot: "fullTimeEducation",
        },
        {
          label: "手机",
          prop: "mobile",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "mobile" },
        },
        {
          label: "邮箱",
          prop: "email",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "email" },
        },
        {
          label: "档案状态",
          prop: "recordType",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "recordType" },
        },
        {
          label: "审批流程状态",
          prop: "checkStatus",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "checkStatus" },
        },
        {
          label: "离职流程状态",
          prop: "dimissionCheckStatus",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "dimissionCheckStatus" },
        },
        {
          label: "在职离职",
          prop: "isDimission",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "isDimission" },
        },
        {
          label: "上岗证",
          prop: "isSgCertificateName",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "isSgCertificateName" },
          slot: "isSgCertificateName",
        },
        {
          label: "进阶证",
          prop: "isJjCertificateName",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "isJjCertificateName" },
          slot: "isJjCertificateName",
        },
        {
          label: "高级证",
          prop: "isGjCertificateName",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "isGjCertificateName" },
          slot: "isGjCertificateName",
        },
        {
          label: "操作",
          prop: "",
          fixed: "right",
          key: "btnGroup",
          slot: "btnGroup",
          width: 220,
        },
      ],
      showAllBUtton: false, //按钮权限
      pager: {
        //分页
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      orderLoading: false, //导出按钮loadding
      tableLoading: false, //表格loadding
    };
  },
  props: {
    cisCode: {},
    fullName: {},
    shopId: {
      type: Number,
    },
    accountId: {
      type: Number,
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    },
  },
  mounted() {
    if (this.$store.state.user.userInfo.account.type == "main") {
      this.getPersonList();
      this.showAllBUtton = true;
    } else {
      this.getPersonList();
      this.showAllBUtton = false;
    }
  },
  methods: {
    // 查询条件
    searchFun(arg) {
      this.pager.pageNo = 1;
      this.arg = arg[0];
      this.getPersonList();
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function(e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      };
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        custId: this.shopId,
        ...this.arg,
      };
      exportData(data)
        .then((res) => {
          Util.blobToJson(res.data)
            .then((content) => {
              if (content && content.code == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch((err) => {
              Util.downloadFile(res.data, "人员列表.xls");
            })
            .finally(() => {
              this.orderLoading = false;
            });
        })
        .catch((err) => {
          this.orderLoading = false;
        });
    },
    // 跳转新增页面
    gotoadd(selRows) {
      this.$router.push({
        path: "/businessAddEdit",
        query: {
          // shopId: this.shopId ,
          jobsName: "业务人员", //岗位
          custFullName: this.fullName, //商家名称|代理商全称
          cisCode: this.cisCode, //CIS编码
          // custAccountName: selRows.custAccountName//账号名称|用户账号
        },
      });
    },
    // 编辑表格
    editTble(selRows) {
      if (selRows.recordType && selRows.recordType.indexOf("审批中") != "-1") {
          //审批中 不可编辑
          this.$warning({
            title: "不可编辑",
            okText: "确定",
            content: "此档案正在审批中，不可编辑！",
          });
      } else if (
        selRows.recordType &&
        selRows.recordType.indexOf("失效") != "-1"
      ) {
        //审批中 不可编辑
          this.$warning({
            title: "不可编辑",
            okText: "确定",
          content: "此档案已失效，不可编辑！",
          });
      }else if((selRows.checkStatus != null && selRows.checkStatus != '发布' && selRows.checkStatus != '驳回') || (selRows.dimissionCheckStatus != "终止" && selRows.dimissionCheckStatus != "作废" && selRows.dimissionCheckStatus != null && selRows.dimissionCheckStatus != "驳回")){
        this.$warning({
          title: "不可编辑",
          okText: "确定",
          content: "离职申请进行中，不可编辑。",
        });
      }else{
        this.$router.push({
          path: "/businessAddEdit",
          query: {
            id: selRows.id,
            // shopId: this.shopId,
            jobsName: selRows.jobsName, //岗位
            custFullName: selRows.custFullName, //商家名称|代理商全称
            cisCode: selRows.cisCode, //CIS编码
            custAccountName: selRows.custAccountName, //账号名称|用户账号
            // phone: selRows.contactTel,
            // email: selRows.email,
            // name: selRows.contactPerson,
            recordType: selRows.recordType, //档案状态
            dimissionCheckStatus: selRows.dimissionCheckStatus, //离职流程状态
            isAllEdit:
              selRows.recordType &&
              (selRows.recordType.indexOf("草稿") != "-1" ||
                selRows.recordType.indexOf("驳回") != "-1")
                ? true
                : false,
            // isNoShenPi:selRows.recordType && ((selRows.recordType.indexOf('生效') != '-1') || (selRows.recordType.indexOf('失效') != '-1') || (selRows.recordType.indexOf('已绑定') != '-1'))? true : false
            isNoShenPi:
              selRows.recordType &&
              (selRows.recordType.indexOf("生效") != "-1" ||
                selRows.recordType.indexOf("失效") != "-1" ||
                selRows.recordType.indexOf("已绑定") != "-1") &&
              (selRows.checkStatus != "驳回" ||
                selRows.dimissionCheckStatus != "驳回")
                ? true
                : false,
          },
        });
      }
    },
    //申请离职
    resignation(row) {
      let this_ = this;
      if ( (row["recordType"] == "生效" && !row['isDimission']) || row["isDimission"] == "在职" ) {
        //有状态
        if (
          row["dimissionCheckStatus"] &&
          row["dimissionCheckStatus"].indexOf("已发布") != -1
        ) {
          this.$warning({
            title: "不可发起",
            okText: "确定",
            content: "离职申请已发布，不可重复发起离职申请。",
          });
        } else if (
          row["dimissionCheckStatus"] == null ||
          row["dimissionCheckStatus"].indexOf("在职") != -1 ||
          row["dimissionCheckStatus"].indexOf("终止") != -1
        ) {
          this.$confirm({
            title: "申请离职",
            content: (<span>即将发起离职流程，通过后档案将失效，与之绑定的账号也将随之注销，且不能再生效！是否确认发起离职申请？<br/>
              <span style='color:#D9161C'>提示：离职流程结束后，档案已绑账号将随之注销并删除</span></span>),
            onOk() {
              let params = {
                id: row["id"].toString(),
                marketCenterIds: row["marketCenterIds"],
              };
              dimission(params)
                .then((res) => {
                  if(res.data.code !=='fail'){
                    this_.$message.success("离职申请提交成功");
                    this_.getPersonList();
                  }else{
                    this_.$message.warning(res.data.msg);
                  }
                })
                .catch((res) => {
                  // console.log(res)
                  this_.$message.error("离职申请提交失败，请联系管理员");
                });
            },
            onCancel() {},
          });
        } else {
          if (row["dimissionCheckStatus"].indexOf("驳回") != -1) {
            this.$warning({
              title: "不可发起",
              okText: "确定",
              content: "该离职申请已被驳回，需重新编辑后即可重新申请。",
            });
          } else {
            this.$warning({
              title: "不可发起",
              okText: "确定",
              content: "离职申请进行中，不可重复发起离职申请。",
            });
          }
        }
      } else {
        this.$warning({
          title: "不可操作",
          okText: "确定",
          content: "该人员已离职或该人员离职状态无法获取",
        });
      }
    },
    //删除
    clearItem(row) {
      console.log(row);
      console.log(row["recordType"]);
      let status = 1;
      let title = "";
      let clearFlag = false; //是否可删除标志
      //有状态
      if (row["recordType"]) {
        if (row["recordType"].indexOf("草稿") != -1) {
          title = "删除后不可恢复，是否确认删除？";
          clearFlag = true;
        } else if (row["recordType"].indexOf("审批中") != -1) {
          title = "此档案正在审批中，不可删除！";
          clearFlag = false;
        } else if (row["recordType"].indexOf("驳回") != -1) {
          title = "删除后不可恢复，是否确认删除？";
          clearFlag = true;
        } else if (row["recordType"].indexOf("生效") != -1) {
          title = "此档案已审批生效，不可删除！";
          clearFlag = false;
        } else if (row["recordType"].indexOf("已绑定") != -1) {
          title = "此档案已绑定账号，不可删除！";
          clearFlag = false;
        } else if (row["recordType"].indexOf("失效") != -1) {
          title = "此档案已失效，且已绑定账号，不可删除！";
          clearFlag = false;
        } else {
          title = "暂未获取此档案状态，不可删除";
          clearFlag = false;
        }
      } else {
        title = "暂未获取此档案状态，不可删除";
        clearFlag = false;
      }

      let this_ = this;
      if (clearFlag) {
        this.$confirm({
          // title: "是否删除该条人员档案",
          title: "删除档案",
          content: title,
          onOk() {
            let params = {
              personId: row["id"].toString(),
            };
            deleteItemC(params)
              .then((res) => {
                if (res.data.code == 0) {
                  this_.$message.success(res.data.msg);
                  this_.getPersonList();
                } else {
                  this_.$message.error(res.data.msg);
                }
              })
              .catch((res) => {
                this_.$message.error(res.data.msg);
              });
          },
          onCancel() {},
        });
      } else {
        this.$warning({
          title: "不可删除",
          okText: "确定",
          content: title,
        });
      }

      // this.$confirm({
      //   title: '此页面修改未保存，是否离开此页面',
      //   onOk() {
      //     this.$router.go(-1);
      //   },
      //   onCancel() {},
      // });
    },
    async getPersonList() {
      this.tableLoading = true;
      const data = {
        queryPage: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          custId: this.shopId,
          ...this.arg,
        },
      };
      const res = await personnelRecordsManagement(data.queryPage);
      this.tableData = res.data.list;
      this.pager.count = res.data.totalRows;
      this.total = res.data.totalRows;
      this.tableLoading = false;
    },
  },
};
