import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
// 获取贸易关系列表
export function getCustBaseMarketList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/user/custBaseMarket/page.nd",
    data: data
  });
}

// 启用销售上报转零售



// 查看商品列表
export function changeSale(data) {
  return dmshttp({
    url: 'saveDealerSalesConvertSetting',
    data
  })
}

// 导出
export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/user/custBaseMarket/export.nd',
      data:data,
      responseType: 'blob'  
  })
}