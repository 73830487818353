import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";


export function terminalActivityByIdStepBuildStore(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/stepBuildStore/terminalActivityById.nd",
    data: data
  });
}

export function getFXlist(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/cust/customerInfoSubmission/page.nd",
    data
  });
}

// 合作关系 /custbase/getDistributorCoopertionList.nd
export function getFXHZlist(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getDistributorCoopertionList.nd",
    data
  });
}


// 变更法人 和退货联动
// let data = {
//   customerCisCode: this.$store.state.user.userInfo.customer.cisCode
// };

// 获取库存状态列表
export function changeLead(data) {
  return dmshttp({
    url: 'updateCustomerInfo',
    data
  })
}

// 导出
export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/cust/customerInfoSubmission/export.nd',
      data:data,
      responseType: 'blob'  
  })
}