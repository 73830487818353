
import { getBaseshopList, getshopCooperateList, exportData } from './api.js'
import Util from "@/utils/utils";
import {
  downloadAuthorizationFile,
  getSpecialShopAuthorizationList
} from "@/views/basicInformation/components/shopManagement/api";
export default {
  data() {
    return {
      arg: {},
      warehouseData: [], // 仓库列表数据
      partnershipDada: [], // 合作关系列表数据
      warehousColumns: [
        {
          label: '门店',
          prop: 'fullName',
          width: 200,
          ellipsis: true,
        },
        {
          label: '门店简称',
          prop: 'shortName',
          width: 150,
          ellipsis: true,
        },
        {
          label: 'CIS编码',
          prop: 'cisCode',
          width: 150,
          ellipsis: true,
        },
        {
          label: 'MDM编码',
          prop: 'mdmCode',
          width: 150,
          ellipsis: true,
        },
        {
          label: '省',
          prop: 'province',
          width: 150,
          ellipsis: true,
        },
        {
          label: '市',
          prop: 'city',
          width: 150,
          ellipsis: true,
        },
        {
          label: '区',
          prop: 'county',
          width: 150,
          ellipsis: true,
        },
        {
          label: '乡镇',
          prop: 'town',
          width: 150,
          ellipsis: true,
        },
        {
          label: '门店地址',
          prop: 'address',
          width: 400,
          ellipsis: true,
        },
        {
          ellipsis: true,
          fixed: "right",
          label: "操作",
          slot: "btnClick",
          width: 100,
        }
      ],
      partnerCloumns: [
        {
          label: '分公司',
          prop: 'fgsName',
          width: 150,
          ellipsis: true,
        },
        {
          label: '办事处',
          prop: 'orgName',
          width: 200,
          ellipsis: true,
        },
        {
          label: '物料组',
          prop: 'baseMatklName',
          width: 150,
          ellipsis: true,
        },
        {
          label: '门店经理',
          prop: 'managerName',
          width: 150,
          ellipsis: true,
        },
        {
          label: '联系电话',
          prop: 'managerTel',
          width: 150,
          ellipsis: true,
        },
        {
          label: '合作状态',
          prop: 'djFlagText',
          width: 150,
          ellipsis: true,
        },
      ],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      tableLoading: false,
      channel:1,//默认下沉门店
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      pagertwo: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      radio: 'radio',
      partnerName: '',
      orderLoading: false,
      sqList:[]
    };
  },
  props: {
    shopId: {
      type: Number,

    }
  },
  watch: {
    shopId: function () {
      this.getCKData()
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  created() {
    this.getSQ()
  },
  mounted() {
    // this.getCKData()
  },
  methods: {
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    // 查询条件
    searchFun(arg) {
      this.pager.pageNo = 1

      this.arg = arg[0]
      this.getCKData();
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        "custId": this.shopId,
        ...this.arg
      }
      exportData(data).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.code == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '我的门店列表.xls')
        }).finally(() => {
          this.orderLoading = false;
        })
      }).catch(err => {
        this.orderLoading = false;
      });
    },
    // 展开收起
    shaiClick() {
      this.isView = !this.isView;
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getCKData();
    },
    async dowmLoad(item){
      await downloadAuthorizationFile({
        authorizationLetterCode:item.specialShopEncoded,
        shopCisCode:item.cisCode,
        shopName:item.fullName
      }).then(res=>{
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", item.specialShopEncoded + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      // ;
    },
   // 获取有授权书的门店
    getSQ(){
      getSpecialShopAuthorizationList({}).then(res=>{
        if ( res.data.code == 0){
          this.sqList = res.data.data
        }
      })
    },
    // 对比有授权书的数据和门店列表数据
    compareList(){
      this.warehouseData.forEach(el=>{
        this.sqList.forEach(item=>{
          if (el.cisCode == item.specialShopEncoded){
            el.specialShopEncoded = item.authorizationLetterCode
          }
        })
      })
    },
    // 仓库列表数据
    async getCKData() {
      this.tableLoading = true
      let data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        "custId": this.shopId,
        channel: this.channel,
        ...this.arg
      }
      
      const res = await getBaseshopList(data)
      this.warehouseData = res.data.list
      // this.pager.pageNo = res.data.page
      // this.pager.pageSize = res.data.pageSize
      if ( this.sqList.length>0){
        this.compareList()
      }
      this.pager.count = res.data.totalRows
      this.total = res.data.totalRows
      // // 设置表格唯一id
      res.data.list.forEach(it => {
        it.uid = it.id
      })
      this.tableLoading = false
    },
    // 合作关系列表数据
    async gethzList() {
      this.tableLoading = true
      const data = {
        queryPage: {
          "page": this.pagertwo.pageNo,
          "pageSize": this.pagertwo.pageSize,
          "custId": this.shopId,
          "queryParamList": []
        },
        custId: this.shopId,
        shopId: this.ckId
      }
      const res = await getshopCooperateList(data)
      this.partnershipDada = res.data.content
      this.pagertwo.pageNo = res.data.page
      this.pagertwo.pageSize = res.data.pageSize
      this.pagertwo.count = res.data.totalRows
      this.total = res.data.totalRows
      this.tableLoading = false
    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record])
          }
        }
      }
    },
    onChange(selRows) {
      this.selectedRowKeys = selRows[0].id;
      this.ckId = this.selectedRowKeys;
      this.partnerName = selRows[0].mdmCode + '-' + selRows[0].fullName
      this.gethzList()
      setTimeout(() => {
        // document.querySelector('#'+'a01').scrollIntoView(true)
        if (this.partnershipDada.length > 0) {
          $("body,html").animate(
            {
              scrollTop: $("#a01").offset().top,
            },
            500
          );
        }
      }, 1000)
    },
  },
};