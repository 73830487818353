import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
// 获取列表
export function getList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/cust/custPolicyInfoList/page.nd",
    data: data
  });
}



// 导出
export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/cust/custPolicyInfoList/export.nd',
      data:data,
      responseType: 'blob'  
  })
}
//预览
export function preview(code) {
    return http({
      method: "get",
      url: "/task/custSignlink.nd?code=" + code
    });
  }