import { getShopInfo, getsonList, getNum } from "./api";
import personnelManagement from "@/views/basicInformation/components/personnelManagement/index.vue";
import businessPersonnelManagement from "@/views/basicInformation/components/businessPersonnelManagement/index.vue";
import distributorManagement from "@/views/basicInformation/components/distributorManagement/index.vue";
import tradeRelations from "@/views/basicInformation/components/tradeRelations/index.vue";
import eContract from "@/views/basicInformation/components/eContract/index.vue";
import basicWarehouse from "@/views/basicInformation/components/basicWarehouse/index.vue";
import shopManagement from "@/views/basicInformation/components/shopManagement/index.vue";
import distributionStores from "@/views/basicInformation/components/distributionStores/index.vue";
import roleManagement from "@/views/basicInformation/components/roleManagement/index.vue";
import sinkManagement from "@/views/basicInformation/components/sinkManagement/index.vue";
import distributionWarehouse from "@/views/basicInformation/components/distributionWarehouse/index.vue";
import policyContract from "@/views/basicInformation/components/policyContract/index.vue"
import { personList } from "@/views/basicInformation/components/personnelManagement/api";
import { getFXlist } from "@/views/basicInformation/components/distributorManagement/api";
import { getBaseshopList } from "@/views/basicInformation/components/shopManagement/api";
import { getBaseWareLis } from "@/views/basicInformation/components/basicWarehouse/api";

export default {
  components: {
    personnelManagement,
    businessPersonnelManagement,
    distributorManagement,
    tradeRelations,
    basicWarehouse,
    shopManagement,
    distributionStores,
    roleManagement,
    sinkManagement,
    distributionWarehouse,
    eContract,
    policyContract
  },
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/basicInfo",
          name: "basicInfo",
          title: "人员账号管理"
        }
      ],
      shopId: this.$store.state.user.userInfo.customer.id,
      shopInfo: [],
      sonData: [],
      active: undefined,
      personTotal: 0, // 人员数量
      FXTotal: 0, // 管辖的分销商数量
      shopTotal: 0, // 门店数量
      CKtotal: 0,
      fgsSize: 0,
      matklSize: 0,
      activeKey: "1",
      defaultActiveKey: "1",
      showAll: true,
      accountId: null
    };
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (this.$route.query.type) {
          this.activeKey = this.$route.query.type;
        }
        // // 检测路由如果是basicInfo 就跳转到type==1的页面上
        if (val.path=='/basicInfo'&&!val.query.type){
          this.$router.push({path:'/basicInfo?type=1'})
        }
        // if(this.activeKey  == 1) {
        //   this. breadcrumbData[1].title = '人员管理'
        // }
      },
      // 深度观察监听
      immediate: true,
      deep: true,

    },

    activeKey: {
      immediate: true,
      handler(newValue) {
        if (newValue == 1) {
          this.breadcrumbData[1].title = "人员账号管理";
        }
        if (newValue == 2) {
          this.breadcrumbData[1].title = "业务人员档案";
        }
        if (newValue == 3) {
          this.breadcrumbData[1].title = "合作的分销商";
        }
        if (newValue == 4) {
          this.breadcrumbData[1].title = "贸易关系";
        }
        if (newValue == 5) {
          this.breadcrumbData[1].title = "收货仓库";
        }
        if (newValue == 6) {
          this.breadcrumbData[1].title = "我的门店";
        }
        if (newValue == 7) {
          this.breadcrumbData[1].title = "分销的门店";
        }
        if (newValue == 8) {
          this.breadcrumbData[1].title = "分销的仓库";
        }
        if (newValue == 9) {
          this.breadcrumbData[1].title = "我的下沉门店";
        }
        if (newValue == 10) {
          this.breadcrumbData[1].title = "电子合同";
        }
        if (newValue == 11) {
          this.breadcrumbData[1].title = "政策合同";
        }
      }
    }
  },
  mounted() {
    this.shopId = this.$store.state.user.userInfo.customer.id;
    this.accountId = this.$store.state.user.userInfo.account.id;
    this.getShopInfo();
    this.getsonData();
    this.getPersonList();
    this.FXList();
    this.getShopData();
    this.getCKData();
    this.getcomNum();
    if (this.$route.query.type) {
      this.activeKey = this.$route.query.type;
    }

  },
  methods: {
    gotoDetailPage(num) {
      if (num == "one") {
        let routeUrl = this.$router.resolve({
          path: "/basicInfo?type=4"
        });
        window.open(routeUrl.href, "_blank");
      }
      if (num == "two") {
        let routeUrl = this.$router.resolve({
          path: "/basicInfo?type=4"
        });
        window.open(routeUrl.href, "_blank");
      }
      if (num == "three") {
        let routeUrl = this.$router.resolve({
          path: "/basicInfo?type=5"
        });
        window.open(routeUrl.href, "_blank");
      }
      if (num == "four") {
        let routeUrl = this.$router.resolve({
          path: "/basicInfo?type=6"
        });
        window.open(routeUrl.href, "_blank");
      }
      if (num == "five") {
        let routeUrl = this.$router.resolve({
          path: "/basicInfo?type=3"
        });
        window.open(routeUrl.href, "_blank");
      }
      if (num == "six") {
        let routeUrl = this.$router.resolve({
          path: "/basicInfo?type=1"
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    // 选择子帐号
    clickSonBox(item) {
      this.active = item.id;
      this.shopId = item.id;
      this.getShopInfo();
      this.getPersonList();
      this.FXList();
      this.getShopData();
      this.getCKData();
    },
    callback() {
    },
    // 获取商家信息
    async getShopInfo() {
      const res = await getShopInfo(this.shopId);
      this.shopInfo = res.data;
    },
    // 获取子帐号
    async getsonData() {
      this.tableLoading = true;
      if (this.$store.state.user.userInfo.account.type == "main") {
        const data = {
          queryPage: {
            "page": 1,
            "pageSize": 10,
            "custId": this.shopId,
            "queryParamList": []
          },
          custId: this.shopId
        };
        const res = await getsonList(data);
        this.sonData = res.data.content;
        this.sonData.unshift({
          id: this.shopId,
          childCisCode: this.shopId,
          childFullName: this.$store.state.user.userInfo.customer.customerName
        });
      } else {
        const data = {
          queryPage: {
            "page": 1,
            "pageSize": 10,
            "custId": this.accountId,
            "queryParamList": []
          },
          custId: this.accountId
        };
        const res = await getsonList(data);
        this.sonData = res.data.content;
      }
    },
    // 人员数据
    async getPersonList() {
      const data = {
        queryPage: {
          "page": 1,
          "pageSize": 10,
          "custId": this.shopId,
          "queryParamList": []
        },
        custId: this.shopId
      };
      const res = await personList(data);
      this.personTotal = res.data.totalRows;
    },
    // 管辖的分销商
    async FXList() {
      const data = {
        queryPage: { "page": 1, "pageSize": 10, "custId": this.shopId, "queryParamList": [] },
        custId: this.shopId
      };
      const res = await getFXlist(data);
      this.FXTotal = res.data.totalRows;
    },
    // 门店列表数据
    async getShopData() {
      const data = {
        queryPage: {
          "page": 1,
          "pageSize": 10,
          "custId": this.shopId,
          "queryParamList": []
        },
        custId: this.shopId
      };
      const res = await getBaseshopList(data);
      this.shopTotal = res.data.totalRows;
    },
    // 仓库列表数据
    async getCKData() {
      const data = {
        queryPage: {
          "page": 1,
          "pageSize": 10,
          "custId": this.shopId,
          "queryParamList": []
        },
        custId: this.shopId
      };
      const res = await getBaseWareLis(data);
      this.CKtotal = res.data.totalRows;
    },
    // 获取分公司和物料组数量
    async getcomNum() {
      const data = {
        queryPage: {
          "page": 1,
          "pageSize": 10,
          "custId": this.shopId,
          "queryParamList": []
        },
        custId: this.shopId
      };
      const res = await getNum(data);
      this.fgsSize = res.data.data.fgsSize;
      this.matklSize = res.data.data.matklSize;
    }
  }
};