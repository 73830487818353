
import { getCustBaseMarketList, changeSale, exportData } from './api.js'
import Util from "@/utils/utils";
export default {
  data() {
    return {
      arg: {},
      tableData: [],
      columns: [
        {
          label: '分公司',
          prop: 'orgName',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'orgName' },
        },
        {
          label: '物料组',
          prop: 'matklName',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'matklName' },
        },
        {
          label: '上级代理',
          prop: 'superiorAgentName',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'superiorAgentName' },
        },
        {
          label: '品牌',
          prop: 'brandText',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'brandText' },
        },

        {
          label: '营销模式',
          prop: 'marketModelText',
          width: 150,
          ellipsis: true,

        },
        {
          label: '合作模式',
          prop: 'cooperationModelText',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'cooperationModelText' },
        },
        {
          label: '业务类型',
          prop: 'ywTypeText',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'ywTypeText' },
        },
        {
          label: '合作状态',
          prop: 'djFlagText',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'djFlagText' },
        },
        {
          label: '订单冻结标识',
          prop: 'orderDjFlagText',
          width: 150,
          ellipsis: true,
        },
        {
          label: '冻结时间',
          prop: 'djTime',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'djTime' },
        },
        {
          label: '共享标识',
          prop: 'shareFlagText',
          width: 150,
          ellipsis: true,

        },
        {
          label: '共享仓(采购)',
          prop: 'shareWarehouseBuy',
          width: 150,
          ellipsis: true,

        },
        {
          label: '共享仓(退货)',
          prop: 'shareWarehouseReturn',
          width: 150,
          ellipsis: true,

        },
        {
          label: '开票方',
          prop: 'party',
          width: 150,
          ellipsis: true,
        },
        {
          label: '付款方',
          prop: 'payer',
          width: 150,
          ellipsis: true,

        },
        {
          label: "是否启用销售上报转零售",
          prop: "",
          key: "btnGroup",
          slot: "btnGroup",
          fixed: 'right',
          width: 210
        }
      ],
      selectedRowKeys: [], // Check here to configure the default column
      selRows: [],
      loading: false,
      tableLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      editingKey: '',
      showbutton: false,
      showAllBUtton: true,
      disabled: false,
      orderLoading: false,
      visibleshop: false,
      checked: '',
    };
  },
  props: {
    shopId: {
      type: Number,
    }
  },
  watch: {
    shopId: function () {
      this.getMYList()
      this.onshow()
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {

    this.getMYList()
    this.onshow()

  },
  methods: {
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        "custId": this.shopId,
        ...this.arg
      }
      exportData(data).then(res => {
        Util.blobToJson(res.data).then(content => {
          debugger
          if (content && content.code == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '贸易关系列表.xls')
        }).finally(() => {
          this.orderLoading = false;
        })
      }).catch(err => {
        this.orderLoading = false;
      });
    },
    onshow() {
      if (this.shopId == this.$store.state.user.userInfo.customer.id) {
        this.showAllBUtton = true
      } else {
        this.showAllBUtton = false
      }
      if (this.$store.state.user.userInfo.account.type == 'main' && this.shopId == this.$store.state.user.userInfo.customer.id) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    // 点击编辑按钮
    changngStatus() {
      if (this.selRows.length < 1) {
        this.$message.warning('请选择数据!')
        return false
      } else {
        this.showbutton = true
      }
    },
    // 取消
    cansel() {
      this.showbutton = false
    },
    // 确定修改
    edit() {
      if (this.selRows.length < 1) {
        this.$message.warning('请选择数据!')
        return false
      } else {
        let checked = null
        if (this.selRows.isDeleted == null || this.selRows.isDeleted == 'F') {
          checked = false
        } else {
          checked = true
        }
        this.changeCherk(checked, this.selRows, this.selRows.id)
      }
    },
    openwinDow(selRows, checked) {

      this.visibleshop = true
      selRows.isDeletedText = selRows.isDeletedText2
      this.checked = checked
      this.selRows = selRows
    },
    // 更改开关
    changeCherk() {

      let isOpen = null
      if (this.selRows.isDeletedText2 == true) {
        isOpen = 1
      } else {
        isOpen = 0
      }
      let data = {
        materialCategory: this.selRows.matklCode,
        isOpen: isOpen
      }

      changeSale(data).then(res => {
        let request = JSON.parse(res.data)
        if (request.code == '0') {
          this.$message.success('修改成功')
          this.getMYList()
          this.selRows = []
          this.showbutton = false
        } else {
          this.$message.warning('修改失败,' + request.msg)
          if (this.selRows.isDeleted == null || this.selRows.isDeleted == 'T') {
          } else if (this.selRows.isDeleted == 'F') {
            this.selRows.isDeletedText = false
          }
          this.showbutton = false
        }
      })
    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record])
          }
        }
      }
    },
    // 点击按钮进行修改
    onChange(selRows) {

      this.selectedRowKeys = selRows[0].id;
      this.selRows = selRows[0]
    },
    // 查询条件
    searchFun(arg) {
      this.pager.pageNo = 1

      this.arg = arg[0]
      this.getMYList();
    },
    // 获取列表数据
    async getMYList() {
      this.tableLoading = true
      const data = {
        "pageNo": this.pager.pageNo,
        "pageSize": this.pager.pageSize,
        "custId": this.shopId,
        ...this.arg
      }
      const res = await getCustBaseMarketList(data)
      this.tableData = res.data.list
      // this.pager.pageNo = res.data.page
      // this.pager.pageSize = res.data.pageSize
      this.pager.count = res.data.totalRows
      this.total = res.data.totalRows
      // 设置表格唯一id
      res.data.list.forEach(it => {
        it.uid = it.id
        if (it.isDeleted == 'null' || it.isDeleted == 'T') {
          it.isDeletedText = false
        } else if (it.isDeleted == 'F') {
          it.isDeletedText = true
        }
        it.isDeletedText2 = it.isDeletedText
      })
      this.tableLoading = false
    },
  },
};