/*
 * @Author: your name
 * @Date: 2021-10-27 17:29:27
 * @LastEditTime: 2021-10-29 13:50:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\basicInformation\components\sinkManagement\api.js
 */
import http from "@/utils/request";
// 获取仓库列表
// export function getBaseshopList(data) {
//   return http({
//     method: "post",
//     // contentType: "application/json;charset=UTF-8",
//     // type: 'stringfy',
//     url: "/fast/user/custShopInfo/page.nd",
//     data: data
//   });
// }

export function getBaseshopList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/user/custShopInfoXc/page.nd",
    data: data
  });
}
// 获取仓库合作关系列表
export function getshopCooperateList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getShopCooperateList.nd",
    data: data
  });
}


export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/user/custShopInfo/export.nd',
      data:data,
      responseType: 'blob'  
  })
}