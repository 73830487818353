import http from "@/utils/request";
// 获取仓库列表
export function getBaseWareLis(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/user/custShopInfoCk/page.nd",
    data: data
  });
}

// 获取仓库合作关系列表
export function getWareCooperateList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/getWareCooperateList.nd",
    data: data
  });
}
// 导出
export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/user/custShopInfoCk/export.nd',
      data:data,
      responseType: 'blob'  
  })
}