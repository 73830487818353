import {
  getFXlist,
  getFXHZlist,
  changeLead,
  exportData,
  terminalActivityByIdStepBuildStore,
} from "./api.js";
import editableCell from "./components/editableCell";
import selectCell from "./components/select";
import Util from "@/utils/utils";
export default {
  components: {
    editableCell,
    selectCell,
  },
  data() {
    return {
      arg: {},
      shopInfo: {},
      tableData: [],
      tableData1: [],
      value: "",
      columns: [
        {
          label: "分销商名称",
          key: "fullName ",
          prop: "fullName",
          width: 200,
          ellipsis: true,
        },
        {
          label: "分销商简称",
          prop: "searchTerm",
          key: "searchTerm ",
          width: 250,
          ellipsis: true,
        },
        {
          label: "CIS编码",
          prop: "cisCode",
          key: "cisCode",
          width: 250,
          ellipsis: true,
        },
        {
          label: " MDM编码",
          prop: "mdmCode",
          key: "mdmCode",
          width: 300,
          ellipsis: true,
        },
        {
          label: "社会信用代码",
          prop: "creditCode",
          key: "creditCode",
          width: 250,
          ellipsis: true,
        },
        {
          label: "法人",
          prop: "legalPerson",
          key: "legalPerson",
          width: 250,
          ellipsis: true,
        },
        {
          label: "联系电话",
          prop: "telephone",
          width: 250,
          ellipsis: true,
        },
        {
          label: "老板名称",
          prop: "bossName",
          ellipsis: true,
          width: 300,
          slot: "bossname",
        },
        {
          label: "退货联动方向",
          // prop: 'returnLinkage',
          // key: 'returnLinkage',
          ellipsis: true,
          slot: "returnLinkage",
          width: 200,
        },
        {
          label: "审批状态",
          prop: "approvalStatus",
          width: 150,
        },
        {
          label: "驳回原因",
          prop: "approvalOpinion",
          width: 200,
          ellipsis: true,
        },
        {
          ellipsis: true,
          fixed: "right",
          label: "操作",
          slot: "btnClick",
          width: 200,
        },
      ],
      columnsButton: [
        {
          label: "商家全称",
          key: "customerInfoName",
          prop: "customerInfoName",
          width: 230,
          ellipsis: true,
        },
        {
          label: "商家CIS编码",
          key: "cisCode",
          prop: "cisCode",
          width: 150,
          ellipsis: true,
        },
        {
          label: "分公司",
          key: "orgName",
          prop: "orgName",
          width: 150,
          ellipsis: true,
        },
        {
          label: "物料组",
          prop: "matklName",
          key: "matklName",
          width: 150,
          ellipsis: true,
        },
        {
          label: "营销模式",
          prop: "marketModelText",
          key: "marketModelText",
          width: 200,
          ellipsis: true,
        },
        {
          label: "上级代理",
          prop: "no",
          key: "no",
          width: 150,
          ellipsis: true,
        },
        {
          label: "品牌",
          prop: "brandText",
          key: "brandText",
          width: 150,
          ellipsis: true,
        },
        {
          label: "合作模式",
          prop: "cooperationModelText",
          key: "cooperationModelText",
          width: 150,
          ellipsis: true,
        },
        {
          label: "业务类型",
          prop: "ywTypeText",
          key: "ywTypeText",
          width: 150,
          ellipsis: true,
        },
        {
          label: "合作状态",
          prop: "djFlagText",
          key: "djFlagText",
          width: 150,
          ellipsis: true,
        },
        {
          label: "冻结时间",
          prop: "djTime",
          key: "djTime",
          width: 150,
          ellipsis: true,
        },
        {
          label: "开票方",
          prop: "party",
          key: "party",
          width: 150,
          ellipsis: true,
        },
        {
          label: "付款方",
          prop: "payer",
          key: "payer",
          width: 300,
          ellipsis: true,
        },
      ],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,

      isActive: 0,
      isActiveLevel: 0,
      organizationValue: undefined,
      materialGroupValue: undefined,
      contacts: "", //联系人
      contactsPhone: "", // 联系电话
      email: "", // 邮箱
      content: "",
      label: "",
      problemLevel: 0, // 问题级别
      problemType: "", // 问题类型
      fileList: [],
      message: "",
      onluyOneMessage: false,
      previewVisible: false,
      previewImage: "",
      tableLoading: false,
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 1,
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      distrueId: "",
      total: 0,
      partnerName: "",
      showButton: true,
      selRows: [],
      showAllBUtton: true,
      orderLoading: false,
    };
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  watch: {
    data: function () {
        
      this.$nextTick(() => { 
        this.tableData1 = [];
        this.distrueId = "";
        this.partnerName = "";
        this.onshow();
        this.FXList();
      });
    },
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
   setTimeout(() => {
     this.FXList();
   }, 2000);
  },
  methods: {
    goTerminalActivityById(feed) {
      let params = {
        id: feed.id || "",
      };
      terminalActivityByIdStepBuildStore(params).then(res => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.FXList();
          this.FXList2()
        }
      });
    },
    goFlow(feed) {
      window.open(
        `/auth-api/displayFlow?param=${feed.processInstId}#/`
      );
    },
    goEdit(feed) {
      let routeUrl = this.$router.resolve({
        path: "/newMerchantaddEdit",
        query: { type: "edit", id: feed.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    addnewMerchant(type) {
      if (type == "add") {
        let routeUrl = this.$router.resolve({
          path: "/newMerchantaddEdit",
        });
        window.open(routeUrl.href, "_blank");
      }

      if (type == "edit") {
        console.log("this.selRows", this.selRows);
        if (this.selectedRowKeys.length == 0) {
          this.$message.warning("请选择一项进行编辑");
          return;
        }
        if (this.selRows.approvalStatus == "流程中") {
          this.$message.warning("只有不是流程中的数据可以编辑,请重新选择");
          return;
        }
        let routeUrl = this.$router.resolve({
          path: "/newMerchantaddEdit",
          query: {
            id: this.selRows.id,
            name: "edit",
          },
        });
        window.open(routeUrl.href, "_blank");
      }
      if (type == "view") {
        if (this.selectedRowKeys.length == 0) {
          this.$message.warning("请选择一项进行查看");
          return;
        }
        let routeUrl = this.$router.resolve({
          path: "/newMerchantaddEdit",
          query: {
            id: this.selRows.id,
            name: "view",
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function(e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      };
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        custId: this.data.id,
        ...this.arg,
      };
      exportData(data)
        .then((res) => {
          Util.blobToJson(res.data)
            .then((content) => {
              if (content && content.code == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch((err) => {
              Util.downloadFile(res.data, "合作的分销商列表.xls");
            })
            .finally(() => {
              this.orderLoading = false;
            });
        })
        .catch((err) => {
          this.orderLoading = false;
        });
    },
    onshow() {
      if (this.shopId == this.$store.state.user.userInfo.customer.id) {
        this.showAllBUtton = true;
      } else {
        this.showAllBUtton = false;
      }
    },
    onselectChange(record, prop, value) {
      let Text = null;
      if (value == "T") {
        Text = 0;
      }
      if (value == "F") {
        Text = 1;
      }
      const dataSource = [...this.tableData];
      const target = dataSource.find((item) => item.id === record.id);
      if (target) {
        let data = {
          customerCisCode: record.cisCode,
          returnLink: Text,
        };
        changeLead(data).then((res) => {
          let requst = JSON.parse(res.data);
          if (requst.code == "0") {
            this.$message.success("保存成功");
            this.FXList();
          } else {
            console.log("requst.msg", requst.msg);
            this.$message.warning("保存失败" + requst.msg, 3);
            setInterval(function() {
              location.reload();
            }, 2000);
          }
        });

        this.tableData = dataSource;
      }

      this.FXList();
    },
    onCellChange(record, prop, value) {
      const dataSource = [...this.tableData];
      const target = dataSource.find((item) => item.id === record.id);
      if (target) {
        let data = {
          legalPerson: value,
          customerCisCode: record.cisCode,
        };
        changeLead(data).then((res) => {
          let requst = JSON.parse(res.data);
          if (requst.code == "0") {
            this.$message.success("保存成功");
            this.FXList();
          } else {
            this.$message.warning("保存失败");
            this.FXList();
          }
        });
      }
    },
    // 查询条件
    searchFun(arg) {
      console.log("arg", arg);
      this.pager.pageNo = 1;

      this.arg = arg[0];
      this.FXList();
    },
    async FXList() {
      let data = {
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        custId: this.data.id,
        ...this.arg,
      };
      this.tableLoading = true;
      const res = await getFXlist(data);
      this.tableLoading = false;
      this.tableData = res.data.list;
      // this.pagination.pageNo = res.data.page
      // this.pagination.pageSize = res.data.pageSize
      this.pagination.count = res.data.totalRows;
      this.total = res.data.totalRows;
      if (res.data.list) {
        res.data.list.forEach((it) => {
          it.uid = it.id;
        });
      }
    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record]);
          },
        },
      };
    },
    // 选择项更改
    onChange(selRows) {
      console.log(selRows);
      this.selectedRowKey = [];
      this.selRows = selRows[0];
      this.selectedRowKeys = this.selRows.id;
      this.distrueId = this.selRows.id;
      if (selRows[0].mdmCode) {
        this.partnerName = selRows[0].mdmCode + "-" + selRows[0].searchTerm;
      } else {
        this.partnerName = selRows[0].searchTerm;
      }
      this.FXList2();

      setTimeout(() => {
        // document.querySelector('#'+'a01').scrollIntoView(true)
        if (this.tableData1.length > 0) {
          $("body,html").animate(
            {
              scrollTop: $("#a01").offset().top,
            },
            500
          );
        }
      }, 1000);
    },
    async FXList2() {
      this.loading = true;
      this.tableData1 = [];
      const data = {
        queryPage: {
          page: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          custId: this.distrueId,
          queryParamList: [
            {
              label: "商家id",
              field: "t.customerInfo.id",
              type: "string",
              isvalid: true,
              logic: "=",
              value: this.distrueId, //选中的数据
            },
          ],
        },
        custId: this.data.id,
      };
      //不知道干嘛的，调用会报错，先注释掉
      // const res = await getFXHZlist(data);
      
      // this.tableData1 = res.data.content;

      // this.loading = false;
      // this.pager.pageNo = res.data.page;
      // this.pager.pageSize = res.data.pageSize;
      // this.pager.count = res.data.totalRows;
      // this.total = res.data.totalRows;
      // res.data.content.forEach((it) => {
      //   it.uid = it.id;
      // });
    },

    pageSearch(pager) {
      this.pagination.pageNo = pager.pageNo;
      this.pagination.pageSize = pager.pageSize;

      this.FXList();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    clickEdit() {
      if (this.selRows.length < 1) {
        this.$message.warning("请至少选择一条数据！");
      } else {
        debugger;
        this.showButton = false;
        this.$refs["refE" + this.selRows.id].editable = true;
        this.$refs["refS" + this.selRows.id].editable = true;
      }
    },
  },
};
