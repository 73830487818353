<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelName"
      :width="798"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>合作关系
        </span>
      </div>

      <div class="contentBox">
        <p >{{name}}</p>
        <div class="tableForm">
          <hi-table
            :data="partnershipDada"
            :header="partnerCloumns"
            border
            fit
            @query="gethzList()"
            :pagination="pagertwo"
            highlight-current-row
            :autoWidth="false"
          >
          </hi-table>
        </div>
      </div>
    </a-modal>

  </div>

</template>

<script>
import { getshopCooperateList } from "@/views/basicInformation/components/shopManagement/api";

export default {
  components:{
  },
  name: "hzTranslation",
  data() {
    return {
      pagertwo: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      partnershipDada:[],
      tableLoading:false,
      partnerCloumns: [
        {
          label: '分公司',
          prop: 'fgsName',
          width: 150,
          ellipsis: true,
        },
        {
          label: '办事处',
          prop: 'orgName',
          width: 200,
          ellipsis: true,
        },
        {
          label: '物料组',
          prop: 'baseMatklName',
          width: 150,
          ellipsis: true,
        },
        {
          label: '门店经理',
          prop: 'managerName',
          width: 150,
          ellipsis: true,
        },
        {
          label: '联系电话',
          prop: 'managerTel',
          width: 150,
          ellipsis: true,
        },
        {
          label: '合作状态',
          prop: 'djFlagText',
          width: 150,
          ellipsis: true,
        },
      ],
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    shopId: {
      type: Number,
    },
    ckId: {
      type: Number,
    },
    name: {
      type: String,
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
       if(newValue){
         this.gethzList()
       }else{
         this.partnershipDada = []
         this.name = ''
         this.ckId= ''
       }
      }
    },

  },
  mounted() {
  },
  methods: {
// 合作关系列表数据
    // 合作关系列表数据
    gethzList() {
      const data = {
        queryPage: {
          "page": this.pagertwo.pageNo,
          "pageSize": this.pagertwo.pageSize,
          "custId": this.shopId,
          "queryParamList": []
        },
        custId: this.shopId,
        shopId: this.ckId
      }
     getshopCooperateList(data).then(res=>{
       this.partnershipDada = res.data.content
       this.pagertwo.pageNo = res.data.page
       this.pagertwo.pageSize = res.data.pageSize
       this.pagertwo.count = res.data.totalRows
     })

    },

    handleOk() {
      this.$emit("update:visible", false);

    },
    cancle() {
      this.$emit("update:visible", false);

    },



  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;



      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 40px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .contentBox {
        .tableForm {
          width: 750px;
          /deep/.pagination-wrap {
            padding:16px 0 24px 0 ;
          }

          /deep/.pagination-wrap .ant-pagination .ant-select-selection{
            width: 100px;

          }
        }
      }

    }

    p {
      font-size: 18px;
      text-align: left;
      font-weight: 500;
      color: #262626;
      margin-bottom: 10px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:8px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


