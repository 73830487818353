import http from "@/utils/request";
// 人员列表
export function personList(data) {
  return http({
    method: "post",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    url: "/fast/user/accountList/page.nd",
    data: data
  });
}

export function personList2(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/getCustBasePermissionDataByAccountId.nd",
    data: data
  });
}
// 权限接口
export function MaterialList(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/getCustBasePermissionDataByCustId.nd",
    data: data
  });
}

export function MaterialList2(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/getCustBasePermissionDataByAccountId.nd",
    data: data
  });
}
// 失效 /customer/updateStatus.nd并不表明            
export function updateStatus(data) {
  return http({
    method: "post",
    url: "/customer/updateStatus.nd",
    data
  });
}
// 重置密码 customer/resetPassword.nd
export function resetPassword(data) {
  return http({
    method: "post",
    url: "/customer/resetPassword.nd",
    data
  });
}
// 保存权限
export function savePermission(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbasePermission/saveCustBasePermissionList.nd",
    data: data
  });
}

// 更改、新增账号
export function editperson(data) {
  return http({
    method: "post",
    url: "/custbasePermission/saveCustBasePermissionList.nd",
    data
  });
}

// 更改、新增账号
export function editRowAccount(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/custbase/editRowAccount.nd",
    data: data
  });
}

// 绑定签章
export function bindSignature(data) {
  return http({
    method: "post",
    url: "/customer/bindSignature.nd",
    data
  });
}
export function  canselSignature(data) {
  return http({
    method: "post",
    url: "/customer/removeBindSignature.nd",
    data
  });
}

// 查询绑定状态

export function  queryBindingAccount(data) {
  return http({
    method: "post",
    url: "/customer/queryBindingAccount.nd",
    data
  });
}
export function exportData(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/user/accountList/export.nd',
      data:data,
      responseType: 'blob'  
  })
}


export function exportDataNew(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/user/accountList/asyncExport.nd',
      data:data,
      // responseType: 'blob'  
  })
}

// 判断当前密码和限制的密码是否一致

export function  comparePassword(data) {
  return http({
    method: "post",
    url: "/customer/isPassword.nd",
    data
  });
}

//提交修改密码
export function  updatePassword(data) {
  return http({
    method: "post",
    url: "/customer/editEnterpriseUser.nd",
    data
  });
}
