import {
  getBaseshopList,
  getshopCooperateList,
  exportData,
  terminalActivityByIdFxshop,
} from "./api.js";
import Util from "@/utils/utils";
export default {
  data() {
    return {
      arg: {},
      warehouseData: [], // 仓库列表数据
      partnershipDada: [], // 合作关系列表数据
      warehousColumns: [
        {
          label: "分销商名称",
          prop: "custInfoFullName",
          width: 200,
          ellipsis: true,
        },
        {
          label: "分销商简称",
          prop: "custInfoSearchTermName",
          width: 200,
          ellipsis: true,
        },
        {
          label: "分销商CIS编码",
          prop: "custInfoCisCode",
          width: 230,
        },
        {
          label: " 分销商MDM编码",
          prop: "custInfoMdmCode",
          width: 320,
        },
        {
          label: "老板名称",
          prop: "legalPerson",
          width: 200,
        },
        {
          label: "门店",
          prop: "fullName",
          width: 200,
        },
        {
          label: "门店简称",
          prop: "searchTerm",
          width: 200,
        },
        {
          label: "门店CIS编码",
          prop: "cisCode",
          width: 200,
        },
        {
          label: " 门店MDM编码",
          prop: "mdmCode",
          width: 200,
        },
        {
          label: "是否是专卖店",
          prop: "isSpecialShopText",
          width: 200,
        },
        {
          label: "省",
          prop: "provinceName",
          width: 200,
        },
        {
          label: "市",
          prop: "cityName",
          width: 200,
        },
        {
          label: "区",
          prop: "countyName",
          width: 200,
        },
        {
          label: "乡镇",
          prop: "townName",
          width: 200,
        },
        {
          label: "门店地址",
          prop: "shAddress",
          width: 400,
          ellipsis: true,
        },
        {
          label: "审批状态",
          prop: "checkStatus",
          width: 200,
          ellipsis: true,
        },
        {
          label: "驳回原因",
          prop: "rejectReason",
          width: 200,
          ellipsis: true,
        },
        {
          ellipsis: true,
          fixed: "right",
          label: "操作",
          slot: "btnClick",
          width: 200,
        },
      ],
      partnerCloumns: [
        {
          label: "分公司",
          prop: "fgsName",
          width: 150,
        },
        {
          label: "办事处",
          prop: "orgName",
          width: 200,
        },
        {
          label: "物料组",
          prop: "baseMatklName",
          width: 150,
        },
        {
          label: "门店经理",
          prop: "managerName",
          width: 150,
        },
        {
          label: "联系电话",
          prop: "managerTel",
          width: 150,
        },
        {
          label: "冻结时间",
          prop: "djTime",
          width: 150,
        },
        {
          label: "合作状态",
          prop: "djFlagText",
          width: 150,
        },
      ],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      tableLoading: false,
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10,
      },
      pagertwo: {
        pageNo: 1,
        pageSize: 10,
        count: 10,
      },
      radio: "radio",
      partnerName: "",

      materialGroupGroup: [
        {
          id: 1,
          code: 2,
          name: "201138302-莱西粮贸仓库2",
        },
        {
          id: 2,
          code: 3,
          name: "201138302-莱西粮贸仓库22",
        },
        {
          id: 3,
          code: 4,
          name: "201138302-莱西粮贸仓库24",
        },
      ], // 物料组
      qustionsTypeArr: [
        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },

        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },
        {
          id: "123",
          name: "123",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
      ],
      ButtonGroup1: [
        {
          id: 0,
          name: "咨询",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
        {
          id: 1,
          name: "投诉",
        },
      ],
      isActive: 0,
      isActiveLevel: 0,
      organizationValue: undefined,
      materialGroupValue: undefined,
      isView: false,
      orderLoading: false,
      ckId: "",
      selectRow: "", //选中的行
      options: [
        {
          value: "zhejiang",
          label: "Zhejiang",
          children: [
            {
              value: "hangzhou",
              label: "Hangzhou",
              children: [
                {
                  value: "xihu",
                  label: "West Lake",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  props: {
    shopId: {
      type: Number,
    },
  },
  watch: {
    shopId: function() {
      this.getCKData();
    },
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.getCKData();
    // this.gethzList()
  },
  methods: {
    goTerminalActivityById(feed) {
      let params = {
        id: feed.id || "",
      };
      terminalActivityByIdFxshop(params).then(res => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);

          this.getCKData();
        }
      });;
    },
    goEdit(feed) {
      let routeUrl = this.$router.resolve({
        path: "/distributionStores/addDistributionStore",
        query: { type: "edit", id: feed.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    goFlow(feed) {
      window.open(
        `/auth-api/displayFlow?param=${feed.processInstId}#/`
      );
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function(e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      };
    },
    exportClick() {
      this.orderLoading = true;
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        custId: this.shopId,
        ...this.arg,
      };
      exportData(data)
        .then((res) => {
          Util.blobToJson(res.data)
            .then((content) => {
              if (content && content.code == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch((err) => {
              Util.downloadFile(res.data, "分销商的门店列表.xls");
            })
            .finally(() => {
              this.orderLoading = false;
            });
        })
        .catch((err) => {
          this.orderLoading = false;
        });
    },
    shaiClick() {
      this.isView = !this.isView;
    },
    // 查询条件
    searchFun(arg) {
      this.pager.pageNo = 1;
      this.arg = arg[0];
      this.getCKData();
    },
    // 仓库列表数据
    async getCKData() {
      this.tableLoading = true;
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        custId: this.shopId,
        ...this.arg,
      };
      const res = await getBaseshopList(data);
      this.warehouseData = res.data.list;
      // this.pager.pageNo = res.data.page
      // this.pager.pageSize = res.data.pageSize
      this.pager.count = res.data.totalRows;
      this.total = res.data.totalRows;
      // // 设置表格唯一id
      if (res.data.list) {
        res.data.list.forEach((it) => {
          it.uid = it.id;
        });
      }

      this.tableLoading = false;
    },

    // 合作关系列表数据
    async gethzList() {
      this.tableLoading = true;
      const data = {
        queryPage: {
          page: this.pagertwo.pageNo,
          pageSize: this.pagertwo.pageSize,
          custId: this.shopId,
          queryParamList: [
            {
              title: "门店id",
              field: "t.custShopInfo.id",
              type: "string",
              isvalid: true,
              logic: "=",
              value: this.ckId,
            },
          ],
        },
        custId: this.shopId,
      };
      const res = await getshopCooperateList(data);
      this.partnershipDada = res.data.content;
      this.pagertwo.pageNo = res.data.page;
      this.pagertwo.pageSize = res.data.pageSize;
      this.pagertwo.count = res.data.totalRows;
      this.total = res.data.totalRows;
      this.tableLoading = false;
    },
    //点击行
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.onChange([record.uid], [record]);
          },
        },
      };
    },
    onChange(selRows) {
      this.selectedRowKeys = selRows[0].id;
      this.ckId = this.selectedRowKeys;
      this.selectRow = selRows[0];
      if (selRows[0].mdmCode) {
        this.partnerName = selRows[0].mdmCode + "-" + selRows[0].searchTerm;
      } else {
        this.partnerName = selRows[0].searchTerm;
      }
      this.gethzList();
      setTimeout(() => {
        // document.querySelector('#'+'a01').scrollIntoView(true)
        if (this.partnershipDada.length > 0) {
          $("body,html").animate(
            {
              scrollTop: $("#a01").offset().top,
            },
            500
          );
        }
      }, 1000);
    },
    // 添加
    add() {
      let routeUrl = this.$router.resolve({
        path: "/distributionStores/addDistributionStore",
        query: { type: "add" },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 查看
    check() {
      if (!this.ckId) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      let routeUrl = this.$router.resolve({
        path: "/distributionStores/addDistributionStore",
        query: { id: this.ckId },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 编辑
    edit() {
      if (!this.ckId) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      if (
        this.selectRow.checkStatus != "草稿" &&
        this.selectRow.checkStatus != "已完成"
      ) {
        this.$message.warning("只有草稿或已完成的数据才可以编辑!");
        return;
      }
      let routeUrl = this.$router.resolve({
        path: "/distributionStores/addDistributionStore",
        query: { id: this.ckId, type: "edit" },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
